import React, { useState } from "react";
import "../sidebar.css";
import Header from "../Header";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import toast, { Toaster } from "react-hot-toast";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const Wallet = () => {
  const [fname, setfname] = useState();
  const [phone, setphone] = useState();
  const [lanme, setlanme] = useState();

  const [position, setposition] = useState();

  const [email, setemail] = useState();

  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const Navigate = useNavigate();

  const [checkedValues, setCheckedValues] = useState([]);

  const [checkboxOptions] = useState([
    { label: "CUSTOMERS", value: "CUSTOMERS" },
    { label: "VENDORS", value: "VENDORS" },
    { label: "DELIVERY MEN", value: "DELIVERY MEN" },
    { label: "CATEGORY", value: "CATEGORY" },
    { label: "PRODUCT ATTRIBUTE", value: "PRODUCT ATTRIBUTE" },
    { label: "ADD ATTRIBUTE", value: "ADD ATTRIBUTE" },
    { label: "VENDOR PRODUCTS", value: "VENDOR PRODUCTS" },
    { label: "ORDER", value: "ORDER" },
    { label: "REFUND REQUEST LIST", value: "REFUND REQUEST LIST" },
    { label: "BANNERS", value: "BANNERS" },
    { label: "ADVERTISEMENT & DEALS", value: "ADVERTISEMENT & DEALS" },
    { label: "NOTIFICATION", value: "NOTIFICATION" },
    { label: "SUGGESTION", value: "SUGGESTION" },
    { label: "MESSAGES", value: "MESSAGES" },
    { label: "ALL TRANSACTION", value: "ALL TRANSACTION" },
    { label: "SETTING", value: "SETTING" },
  ]);

  const generateRestrictionsObject = (checkedValues) => {
    const restrictions = {};

    if (checkedValues.includes("CUSTOMERS")) {
      restrictions.customers = [
        "/Customer List",
        "/Customer Reviews",
        "/Loyalty Points",
      ];
    }
    if (checkedValues.includes("VENDORS")) {
      restrictions.vendors = [
        "/Add New Vendor",
        "/Vendor List",
        "/Vendor Withdrow",
      ];
    }
    if (checkedValues.includes("DELIVERY MEN")) {
      restrictions.delivery_men = [
        "/Add New Deliveryman",
        "/Delivery Man List",
        "/Delivery Man Withdrow",
        "/Emergency Contact",
      ];
    }
    if (checkedValues.includes("CATEGORY")) {
      restrictions.category = [
        "/Main Cetagory",
        "/Cetagory",
        "/Subcategory",
        "/Sub Subcetagory",
      ];
    }
    if (checkedValues.includes("PRODUCT ATTRIBUTE")) {
      restrictions.PRODUCT_ATTRIBUTE = ["/Brands", "/Product Type"];
    }

    if (checkedValues.includes("ADD ATTRIBUTE")) {
      restrictions.add_attribute = ["/Add Attribute"];
    }
    if (checkedValues.includes("VENDOR PRODUCTS")) {
      restrictions.vendor_products = [
        "/New Products Requests",
        "/Approved Products",
        "/Denied Products",
      ];
    }
    if (checkedValues.includes("ORDER")) {
      restrictions.order = [
        "/Pending",
        "/Confirmed",
        "/Rejected",
        "/Shipped",
        "/Delivered",
        "/Returned",
        "/Not delivered",
        "/Cancelled",
      ];
    }
    if (checkedValues.includes("REFUND REQUEST LIST")) {
      restrictions.refund_request_list = [
        "/Pending Refund",
        "/Approved Refund",
        "/Refunded Refund",
        "/Rejected Refund",
      ];
    }

    if (checkedValues.includes("BANNERS")) {
      restrictions.banners = ["/Banners"];
    }

    if (checkedValues.includes("ADVERTISEMENT & DEALS")) {
      restrictions.advertisement_deals = [
        "/Add Advertisement",
        "/Deal of the day Innt",
        "/Today Offer InntOut",
      ];
    }

    if (checkedValues.includes("NOTIFICATION")) {
      restrictions.notification = ["/Send Notification"];
    }
    if (checkedValues.includes("SUGGESTION")) {
      restrictions.suggestion = ["/Suggestion"];
    }

    if (checkedValues.includes("MESSAGES")) {
      restrictions.messages = ["/Messages"];
    }

    if (checkedValues.includes("ALL TRANSACTION")) {
      restrictions.all_transaction = ["/All Transaction"];
    }

    if (checkedValues.includes("SETTING")) {
      restrictions.setting = [
        "/Tax & Fare charge",
        "/About Us",
        "/Term & Conditions",
        "/Return Policy",
        "/Cancellation Policy",
        "/Refund Policy",
        "/Privacy & Policy",
        "/Faq",
        "/Contact Us",
      ];
    }
    return restrictions;
  };

  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((item) => item !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };

  const staffdata = (e) => {
    e.preventDefault();
    if (password != confirmpassword) {
      toast.error("Password & Confirm Password Not Matched");
      return;
    }

    const restrictions = generateRestrictionsObject(checkedValues);
    const formData = {
      first_name: fname,
      address: phone,
      last_name: lanme,
      password: password,
      email: email,

      restrictions: JSON.stringify(restrictions),
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}admin/api/AdminSignup`, formData)
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          Navigate("/Staff List");
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };

  return (
    <div>
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-3 col-md-4">{/* <Sidebarr /> */}</div>

        <div className="col-lg-9 col-md-8" style={{ marginTop: "60px" }}>
          <div className="mt-3 mb-5">
            <div className="mb-3 d-flex justify-content-between flex-wrap gap-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img
                  width={20}
                  src="img/staff.png"
                  alt=""
                />
                Add New Staff
              </h2>
              <button
                onClick={() => Navigate("/Staff List")}
                type="button"
                className="btn btn--primary text-capitalize"
              >
                Staff List
              </button>
            </div>

            <div className="card  mt-3">
              <div className="card-header text-capitalize">
                <h4 className="mb-0">Staff Details</h4>
              </div>
              <div className="card-body" >
                <div className="row">
                  <div className="col-lg-12 pt-3">
                    <form onSubmit={staffdata}>
                      <div class="card" style={{borderColor:'rgb(188, 189, 190)'}}>
                        <div class="card-body">
                          <h5 class="mb-0 page-header-title text-capitalize d-flex align-items-center gap-2 border-bottom pb-3 mb-3">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            Personal information
                          </h5>
                          <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                              <div class="form-group">
                                <label for="name" class="title-color">
                                  First name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  value={fname}
                                  onChange={(e) => {
                                    setfname(e.target.value);
                                  }}
                                  type="text"
                                  name="name"
                                  class="form-control"
                                  id="name"
                                  placeholder="First Name"
                                  required
                                />
                              </div>
                              <div class="form-group">
                                <label for="name" class="title-color">
                                  Last name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  class="form-control"
                                  id="name"
                                  placeholder="Last Name"
                                  value={lanme}
                                  onChange={(e) => {
                                    setlanme(e.target.value);
                                  }}
                                  required
                                />
                              </div>
                              <div class="form-group">
                                <label for="Address" class="title-color">
                                  Address<span className="text-danger">*</span>
                                </label>
                                <div class="mb-3">
                                  <div class="">
                                    <input
                                      required
                                      type="text"
                                      class="form-control"
                                      value={phone}
                                      onChange={(e) => {
                                        setphone(e.target.value);
                                      }}
                                      name="phone"
                                      placeholder="Enter Address here"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label for="role_id" class="title-color">
                                  Role<span className="text-danger">*</span>
                                </label>
                                <input type="text" placeholder="Please Enter Staff Role"
                                  required
                                  value={position}
                                  onChange={(e) => {
                                    setposition(e.target.value);
                                  }}
                                  class="form-control"
                                  name="role_id"
                                  id="role_id"
                                >
                                 
                                </input>
                              </div>

                              <div className="form-group">
                                <label htmlFor="email" className="title-color">
                                  Email / Phone Number<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  value={email}
                                  onChange={(e) => {
                                    setemail(e.target.value);
                                  }}
                                  className="form-control"
                                  id="email"
                                  placeholder="Enter Email / Phone Number"
                                  required
                                />
                              </div>

                              <div className="form-group">
                                <label
                                  htmlFor="user_password"
                                  className="title-color d-flex align-items-center"
                                >
                                  Password<span className="text-danger">*</span>
                                </label>
                                <div className="input-group input-group-merge">
                                  <input
                                    minLength={8}
                                    value={password}
                                    onChange={(e) => {
                                      setpassword(e.target.value);
                                    }}
                                    type="text"
                                    className="js-toggle-password form-control password-check"
                                    name="password"
                                    required
                                    id="user_password"
                                    placeholder="Password minimum 8 characters"
                                  />
                                  <div
                                    id="changePassTarget"
                                    className="input-group-append"
                                  >
                                    <a
                                      className="input-group-text"
                                      href="javascript:"
                                    >
                                      <i
                                        id="changePassIcon"
                                        className="tio-hidden-outlined"
                                      />
                                    </a>
                                  </div>
                                </div>
                                <span className="text-danger mx-1 password-error" />
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="confirm_password"
                                  className="title-color"
                                >
                                  Confirm password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group input-group-merge">
                                  <input
                                    value={confirmpassword}
                                    onChange={(e) => {
                                      setconfirmpassword(e.target.value);
                                    }}
                                    type="text"
                                    className="js-toggle-password form-control"
                                    name="confirm_password"
                                    required
                                    id="confirm_password"
                                    placeholder="Confirm password"
                                  />
                                  <div
                                    id="changeConfirmPassTarget"
                                    className="input-group-append"
                                  >
                                    <a
                                      className="input-group-text"
                                      href="javascript:"
                                    >
                                      <i
                                        id="changeConfirmPassIcon"
                                        className="tio-hidden-outlined"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-6 col-sm-12">
                              <label className="title-color">
                                Restrictions: {checkedValues.join(", ")}
                              </label>
                              <div className="form-group mb-0">
                                {checkboxOptions?.map((option, index) => (
                                  <div
                                    key={index}
                                    className="border rounded px-2 py-3 d-flex justify-content-between mb-1"
                                  >
                                    <h5 className="mb-1 d-flex gap-1 c1">
                                      {option.label}
                                    </h5>
                                    <div className="position-relative">
                                      <label className="switcher">
                                        <input
                                          type="checkbox"
                                          className="switcher_input toggle-switch-message"
                                          name={option.value}
                                          checked={checkedValues.includes(
                                            option.value
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(option.value)
                                          }
                                        />
                                        <span className="switcher_control"></span>
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end gap-3 mt-3">
                            <button
                              type="submit"
                              className="btn btn--primary px-4"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <div className="card mt-3 mb-3">
                        <div className="card-body">
                          <h5 className="mb-0 page-header-title d-flex align-items-center gap-2 border-bottom pb-3 mb-3">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            Account Information
                          </h5>
                          <div className="row">
                            <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="email" className="title-color">
                                  Email<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  value={email}
                                  onChange={(e) => {
                                    setemail(e.target.value);
                                  }}
                                  className="form-control"
                                  id="email"
                                  placeholder="Enter Email"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                              <div className="form-group">
                                <label
                                  htmlFor="user_password"
                                  className="title-color d-flex align-items-center"
                                >
                                  Password<span className="text-danger">*</span>
                                </label>
                                <div className="input-group input-group-merge">
                                  <input
                                    minLength={8}
                                    value={password}
                                    onChange={(e) => {
                                      setpassword(e.target.value);
                                    }}
                                    type="password"
                                    className="js-toggle-password form-control password-check"
                                    name="password"
                                    required
                                    id="user_password"
                                    placeholder="Password minimum 8 characters"
                                  />
                                  <div
                                    id="changePassTarget"
                                    className="input-group-append"
                                  >
                                    <a
                                      className="input-group-text"
                                      href="javascript:"
                                    >
                                      <i
                                        id="changePassIcon"
                                        className="tio-hidden-outlined"
                                      />
                                    </a>
                                  </div>
                                </div>
                                <span className="text-danger mx-1 password-error" />
                              </div>
                            </div>
                            <div className="col-md-6 col-xxl-6 col-lg-4 col-xl-4 col-sm-12">
                              <div className="form-group">
                                <label
                                  htmlFor="confirm_password"
                                  className="title-color"
                                >
                                  Confirm password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group input-group-merge">
                                  <input
                                    value={confirmpassword}
                                    onChange={(e) => {
                                      setconfirmpassword(e.target.value);
                                    }}
                                    type="password"
                                    className="js-toggle-password form-control"
                                    name="confirm_password"
                                    required
                                    id="confirm_password"
                                    placeholder="Confirm password"
                                  />
                                  <div
                                    id="changeConfirmPassTarget"
                                    className="input-group-append"
                                  >
                                    <a
                                      className="input-group-text"
                                      href="javascript:"
                                    >
                                      <i
                                        id="changeConfirmPassIcon"
                                        className="tio-hidden-outlined"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end gap-3">
                            <button
                              type="submit"
                              className="btn btn--primary px-4"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
