import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const Deliverycharge = () => {
  const [deliverycharge, setdeliverycharge] = useState();
  const [commission, setcommission] = useState();
  const [charges, setcharges] = useState();
  const [deliveryCharges, setdeliveryCharges] = useState();
  
  useEffect(() => {
    getcharges();
  }, [0]);

  const getcharges = (e) => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}admin/api/Commission_delivery_list`)
      .then((res) => {
        setcharges(res.data.data);
        setcommission(res?.data?.data?.commission);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getdeliverycharges();
  }, [0]);

  const getdeliverycharges = (e) => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}admin/api/Delivery_charge_list`)
      .then((res) => {
        setdeliveryCharges(res.data.data);
      })
      .catch((error) => {});
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    let chargesdata = {
      delivery_charge: deliverycharge
        ? deliverycharge
        : charges?.delivery_charge,
      commission: commission ? commission : charges?.commission,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}admin/api/createCommission_delivery`,
        chargesdata
      )
      .then((res) => {
        toast.success(res.data.message);
        getcharges();
      })
      .catch((error) => {});
    setdeliverycharge("");
    setcommission("");
  };

  const [startdistance, setstartdistance] = useState("");
  const [enddistance, setenddistance] = useState("");
  const [status, setstatus] = useState("false");
  const [open,setopen] = useState("false");
  const [fares, setFares] = useState([
    { s_time: "00:00", e_time: "05:59", fare: "" },
    { s_time: "06:00", e_time: "11:59", fare: "" },
    { s_time: "12:00", e_time: "17:59", fare: "" },
    { s_time: "18:00", e_time: "23:59", fare: "" },
  ]);

  const handleFareChange = (index, value) => {
    const updatedFares = [...fares];
    updatedFares[index].fare = value;
    setFares(updatedFares);
  };

  const handlesubmitfordelivery = (e) => {
    e.preventDefault();
    let chargesdata = {
      s_distance: startdistance,
      e_distance: enddistance,
      fare: fares,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}admin/api/addDelivery_charge`,
        chargesdata
      )
      .then((res) => {
        setstatus(false);
        setopen(false)
        toast.success("Charges Added Successfully");
        getcharges();
        getdeliverycharges();
      })
      .catch((error) => {
        toast.error("Failed to add charges");
      });

    setstartdistance("");
    setenddistance("");
    setFares([
      { s_time: "00:00", e_time: "05:59", fare: "" },
      { s_time: "06:00", e_time: "11:59", fare: "" },
      { s_time: "12:00", e_time: "17:59", fare: "" },
      { s_time: "18:00", e_time: "23:59", fare: "" },
    ]);
  };



const [id, setid] = useState(null);
const [startDistance, setStartDistance] = useState("");
const [endDistance, setEndDistance] = useState("");
const [faress, setFaress] = useState([
  { s_time: "00:00", e_time: "05:59", fare: "" },
  { s_time: "06:00", e_time: "11:59", fare: "" },
  { s_time: "12:00", e_time: "17:59", fare: "" },
  { s_time: "18:00", e_time: "23:59", fare: "" },
]);

const handleFareChanged = (index, value) => {
  const updatedFaress = [...faress];
  updatedFaress[index].fare = value;
  setFaress(updatedFaress);
};
const updatedeliverycharges = (e) => {
  e.preventDefault();
  let chargesdata = {
    id:id,
    s_distance: startDistance,
    e_distance: endDistance,
    fare: faress,
  };

  axios
    .post(
      `${process.env.REACT_APP_API_KEY}admin/api/update_delivery_charge`,
      chargesdata
    )
    .then((res) => {

      setstatus(false);
      setopen(false)
      toast.success("Charges Update Successfully");
      getcharges();
      getdeliverycharges();
    })
    .catch((error) => {
      toast.error("Failed to add charges");
    });

  setstartdistance("");
  setenddistance("");
  setFaress([
    { s_time: "00:00", e_time: "05:59", fare: "" },
    { s_time: "06:00", e_time: "11:59", fare: "" },
    { s_time: "12:00", e_time: "17:59", fare: "" },
    { s_time: "18:00", e_time: "23:59", fare: "" },
  ]);
  resetForm();
};




const resetForm = () => {
  setStartDistance("");
  setEndDistance("");
  setFaress([
    { s_time: "00:00", e_time: "05:59", fare: "" },
    { s_time: "06:00", e_time: "11:59", fare: "" },
    { s_time: "12:00", e_time: "17:59", fare: "" },
    { s_time: "18:00", e_time: "23:59", fare: "" },
  ]);
};




  return (
    <div>
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-3 col-md-4">{/* <Sidebarr /> */}</div>

        <div className="col-lg-9 col-md-8" style={{ marginTop: "60px" }}>
          <div className="mt-3 mb-5">
            <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
              <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                <img width={20} src="img/cash-back.png" alt />
                Delivery Fare & Platform fees
              </h2>
            </div>
            <div
              className="row pb-4 d--none"
              id="main-banner"
              style={{ textAlign: "left" }}
            ></div>

            <div className="row g-3 mb-3">
              <div className="col-md-12">
                <input
                  type="hidden"
                  name="_token"
                  defaultValue="UUv9Mglm04V3CqIyFGCPNvVg28RwTzVCaKRLpfdW"
                  autoComplete="off"
                />{" "}
                <div
                  className="card"
                  style={{ borderColor: "rgb(188, 189, 190)" }}
                >
                  <div className="card-header">
                    <h5 className="mb-0 text-capitalize">
                      {" "}
                      Delivery charge :{" "}
                    </h5>
                    <div class="d-flex gap-3 justify-content-end">
                      <button
                        onClick={() => {
                          setstatus(true);
                          setopen(false)
                          resetForm()
                          setFaress("");
                          
                        }}
                        class="btn btn--primary px-4"
                      >
                        Add Charges
                      </button>
                    </div>
                  </div>
                  {status === true ? (
                    <div className="card-body row">
                      <div className="col-md-6">
                        <form
                          onSubmit={handlesubmitfordelivery}
                          encType="multipart/form-data"
                        >
                          <div className="row text-center">
                            <div className=" col-sm-6 col-lg-6 col-md-4 mt-2 ">
                              From{" "}
                              <input
                                required
                                onChange={(e) => {
                                  setstartdistance(e.target.value);
                                }}
                                value={startdistance}
                                placeholder="-"
                                type="number"
                                style={{
                                  textAlign: "center",
                                  width: "47%",
                                  border: ".0625rem solid #e7eaf3",
                                  height: "30px",
                                  borderColor: "#d0dbe9",
                                  borderRadius: ".3125rem",
                                }}
                              />{" "}
                              Km
                            </div>
                            <div className=" col-sm-6 col-lg-6 col-md-4 mt-2 ">
                              to -{" "}
                              <input
                                placeholder="-"
                                required
                                onChange={(e) => {
                                  setenddistance(e.target.value);
                                }}
                                value={enddistance}
                                type="number"
                                style={{
                                  width: "47%",
                                  textAlign: "center",
                                  border: ".0625rem solid #e7eaf3",
                                  height: "30px",
                                  borderColor: "#d0dbe9",
                                  borderRadius: ".3125rem",
                                }}
                              />{" "}
                              Km
                            </div>
                          </div>
                          <table
                            style={{
                              borderColor: "rgb(188, 189, 190)",
                              border: "1px solid rgb(188, 189, 190)",
                            }}
                            className="mt-3 mb-3 table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100 text-left"
                          >
                            <thead className=" thead-30 text-capitalize">
                              <tr>
                                <th
                                  className="text-center"
                                  style={{
                                    border: "1px solid rgb(188, 189, 190)",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  className="text-center"
                                  style={{
                                    border: "1px solid rgb(188, 189, 190)",
                                  }}
                                >
                                  Fare
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fares?.map((fare, index) => (
                                <tr key={index} className="text-center">
                                  <td
                                    style={{
                                      border: "1px solid rgb(188, 189, 190)",
                                    }}
                                  >
                                    {fare.s_time} - {fare.e_time}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid rgb(188, 189, 190)",
                                    }}
                                  >
                                    <input
                                      placeholder="-"
                                      required
                                      value={fare.fare}
                                      onChange={(e) =>
                                        handleFareChange(index, e.target.value)
                                      }
                                      type="number"
                                      style={{
                                        textAlign: "center",
                                        border: ".0625rem solid #e7eaf3",
                                        height: "30px",
                                        borderColor: "#d0dbe9",
                                        borderRadius: ".3125rem",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <button type="submit" className=" btn btn--primary">
                            Add Charges{" "}
                          </button>
                        </form>
                      </div>
                    </div>
                  ) : null}

                  {open == true ? ( <div className="card-body row">
                    <div className="col-md-6">
            <form onSubmit={updatedeliverycharges} encType="multipart/form-data">
              <div className="row text-center">
                <div className="col-sm-6 col-lg-6 col-md-4 mt-2">
                  From{" "}
                  <input
                    required
                    onChange={(e) => {
                      setStartDistance(e.target.value);
                    }}
                    value={startDistance}
                    placeholder="-"
                    type="number"
                    style={{
                      textAlign: "center",
                      width: "47%",
                      border: ".0625rem solid #e7eaf3",
                      height: "30px",
                      borderColor: "#d0dbe9",
                      borderRadius: ".3125rem",
                    }}
                  />{" "}
                  Km
                </div>
                <div className="col-sm-6 col-lg-6 col-md-4 mt-2">
                  to -{" "}
                  <input
                    placeholder="-"
                    required
                    onChange={(e) => {
                      setEndDistance(e.target.value);
                    }}
                    value={endDistance}
                    type="number"
                    style={{
                      width: "47%",
                      textAlign: "center",
                      border: ".0625rem solid #e7eaf3",
                      height: "30px",
                      borderColor: "#d0dbe9",
                      borderRadius: ".3125rem",
                    }}
                  />{" "}
                  Km
                </div>
              </div>
              <table
                style={{
                  borderColor: "rgb(188, 189, 190)",
                  border: "1px solid rgb(188, 189, 190)",
                }}
                className="mt-3 mb-3 table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100 text-left"
              >
                <thead className="thead-30 text-capitalize">
                  <tr>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid rgb(188, 189, 190)",
                      }}
                    >
                      Time
                    </th>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid rgb(188, 189, 190)",
                      }}
                    >
                      Fare
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {faress?.map((fare, index) => (
                    <tr key={index} className="text-center">
                      <td
                        style={{
                          border: "1px solid rgb(188, 189, 190)",
                        }}
                      >
                        {fare.s_time} - {fare.e_time}
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(188, 189, 190)",
                        }}
                      >
                        <input 
                          placeholder="-"
                          required
                          value={fare.fare}
                          onChange={(e) =>
                            handleFareChanged(index, e.target.value)
                          }
                          type="number"
                          style={{
                            textAlign: "center",
                            border: ".0625rem solid #e7eaf3",
                            height: "30px",
                            borderColor: "#d0dbe9",
                            borderRadius: ".3125rem",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button type="submit" className="btn btn--primary">
                Update charges{" "}
              </button>
            </form>
          </div>
                    </div>) : null}
                  <div className="card-body row">
                    {deliveryCharges?.map((data) => {
                      return (
                        <div className="col-md-4 mb-3">
                         <a  onClick={() => {
              setopen(true);
              setstatus(false);
              setid(data._id);
              setStartDistance(data.s_distance);
              setEndDistance(data.e_distance);
              setFaress(data.fare);
            }} href="#">
                          <div className="row text-center">
                            <div className=" col-sm-6 col-lg-6 col-md-4 mt-2 ">
                             From{" "}
                              <input
                                value={data?.s_distance}
                                placeholder="-"
                                type="number"
                                style={{
                                  textAlign: "center",
                                  width: "23%",
                                  border: ".0625rem solid #e7eaf3",
                                  height: "30px",
                                  borderColor: "#d0dbe9",
                                  borderRadius: ".3125rem",
                                }}
                              />{" "}
                              Km
                            </div>
                            <div className=" col-sm-6 col-lg-6 col-md-4 mt-2 ">
                              to -{" "}
                              <input
                                placeholder="-"
                                required
                                value={data?.e_distance}
                                type="number"
                                style={{
                                  width: "23%",
                                  textAlign: "center",
                                  border: ".0625rem solid #e7eaf3",
                                  height: "30px",
                                  borderColor: "#d0dbe9",
                                  borderRadius: ".3125rem",
                                }}
                              />{" "}
                              Km
                            </div>
                          </div>
                          <table
                            style={{
                              borderColor: "rgb(188, 189, 190)",
                              border: "1px solid rgb(188, 189, 190)",
                            }}
                            className="mt-3 mb-3 table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100 text-left"
                          >
                            <thead className=" thead-30 text-capitalize">
                              <tr>
                                <th
                                  className="text-center"
                                  style={{
                                    border: "1px solid rgb(188, 189, 190)",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  className="text-center"
                                  style={{
                                    border: "1px solid rgb(188, 189, 190)",
                                  }}
                                >
                                  Fare
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.fare?.map((faree, index) => (
                                <tr key={index} className="text-center">
                                  <td
                                    style={{
                                      border: "1px solid rgb(188, 189, 190)",
                                    }}
                                  >
                                    {faree?.s_time} - {faree?.e_time}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid rgb(188, 189, 190)",
                                    }}
                                  >
                                    <input
                                      placeholder="-"
                                      required
                                      value={faree?.fare}
                                      type="number"
                                      style={{
                                        width: "50%",
                                        textAlign: "center",
                                        border: ".0625rem solid #e7eaf3",
                                        height: "30px",
                                        borderColor: "#d0dbe9",
                                        borderRadius: ".3125rem",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <input
                  type="hidden"
                  name="_token"
                  defaultValue="UUv9Mglm04V3CqIyFGCPNvVg28RwTzVCaKRLpfdW"
                  autoComplete="off"
                />{" "}
                <div
                  className="card"
                  style={{ borderColor: "rgb(188, 189, 190)" }}
                >
                  <div className="card-header">
                    <h5 className="mb-0"> Default platform fees :</h5>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handlesubmit} encType="multipart/form-data">
                      <small className="badge text-wrap badge-soft-danger mb-3">
                        Fees .
                        {/* If Tax number is disabled here it will not show in invoice. */}
                      </small>
                      <div className="form-group">
                        <label> Platform fees </label>
                        <input
                          style={{ width: "50%" }}
                          type="number"
                          onChange={(e) => {
                            setcommission(e.target.value);
                          }}
                          value={commission}
                          className="form-control"
                          name="gst"
                        />{" "}
                        <div class="d-flex gap-3 justify-content-end">
                          <button type="submit" className="btn btn--primary">
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliverycharge;
