import React, { useEffect, useState } from "react";
import "../sidebar.css";
import Header from "../Header";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Pagination from "react-js-pagination";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";
const Sallertransacations = () => {
  const [count, setcount] = useState();
  const [sellerlist, setSellerlist] = useState([]);
  const [filteredSellerList, setFilteredSellerList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  let token = secureLocalStorage.getItem("adminidtoken");
  let sellerid = secureLocalStorage.getItem("sellerid");
  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(sellerlist);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Seller List");

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");

    if (document.body) {
      document.body.appendChild(link);

      link.setAttribute("href", url);
      link.setAttribute("download", "sellertransaction.xlsx");

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
    }
  };

  useEffect(() => {
    getsellerlist();
  }, [0]);
  const data = {
    venderId: sellerid,
  };
  let getsellerlist = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}admin/api/vendor_transjection_list`,
        data
      )
      .then((res) => {
        setcount(res?.data?.data?.length);
        setSellerlist(res.data.data);
        setFilteredSellerList(res.data.data);
      })
      .catch((error) => {});
  };

  const handleFilter = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const result = sellerlist.filter(
      (item) =>
        item?.shop_name?.toLowerCase().includes(searchTerm) ||
        item?.customer_name?.toLowerCase().includes(searchTerm) ||
        
        
        item?.payment_status?.toLowerCase().includes(searchTerm)
    );
    setFilteredSellerList(result);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const renderSellerData = (seller, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr key={index}>
        <td>{adjustedIndex}</td>
        <td className="text-capitalize">{seller?.shop_name}</td>
        <td className="text-capitalize">{seller?.customer_name}</td>
        <td className="text-capitalize">{seller?.orderId}</td>
        <td className="text-capitalize ">
          {seller?.order_status === 0 && (
            <label className="badge badge-soft-secondary">Pending</label>
          )}

          {seller?.order_status === 1 && (
            <label className="badge badge-soft-danger">Cancel</label>
          )}
          {seller?.order_status === 2 && (
            <label className="badge badge-soft-primary">Confirm</label>
          )}
          {seller?.order_status === 3 && (
            <label className="badge badge-soft-warning">Rejected</label>
          )}
          {seller?.order_status === 4 && (
            <label className="badge badge-soft-warning">Shipped</label>
          )}
          {seller?.order_status === 5 && (
            <label className="badge badge-soft-black">Not Delivered</label>
          )}
          {seller?.order_status === 6 && (
            <label className="badge badge-soft-success">Delivered</label>
          )}
          {seller?.order_status === 7 && (
            <label className="badge badge-soft-success">Return</label>
          )}
        </td>
        <td className="text-capitalize">{seller?.tranjectionId}</td>
        <td className="text-capitalize">{seller?.order_amount} CFA</td>
        <td className="text-capitalize">{seller?.vender_amount} CFA</td>
        <td className="text-capitalize">{seller?.comission} CFA</td>
        {/* <td className="text-capitalize">dsfsdf</td> */}
        <td className="text-capitalize">{seller?.delivery_type}</td>
        <td className="text-capitalize">{seller?.delivery_charge} CFA</td>
        {seller?.delivery_type == "Pickup" ? (
          <td className="text-capitalize">Offline</td>
        ) : (
          <td className="text-capitalize">Online</td>
        )}

        <td className="text-capitalize">{seller?.tax}</td>
        {/* <td className="text-capitalize">{seller?.payment_status}</td> */}
        {seller?.payment_status === "Paid" ? (
          <td class="text-capitalize">
            <label class="badge badge-soft-success">
              {seller?.payment_status}
            </label>
          </td>
        ) : (
          <td class="text-capitalize">
            <label class="badge badge-soft-danger">
              {seller?.payment_status}
            </label>
          </td>
        )}
      </tr>
    );
  };
  return (
    <div>
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-3 col-md-4">{/* <Sidebarr /> */}</div>

        <div className="col-lg-9 col-md-8" style={{ marginTop: "60px" }}>
          <div className="mt-3 mb-5">
            <h2 className="h1 mb-0 text-capitalize d-flex gap-2 align-items-center">
              <img width={20} src="img/officer.png" alt />
              Vendor details
            </h2>
          </div>
          <div className="flex-between d-sm-flex row align-items-center justify-content-between mb-2 mx-1">
            <div></div>
          </div>
          <div className="page-header">
            {/* <div className="flex-between row mx-1">
                                <div>
                                    <h1 className="page-header-title">Digital House</h1>
                                </div>
                            </div> */}
            <div className="js-nav-scroller hs-nav-scroller-horizontal">
              <ul className="nav nav-tabs flex-wrap page-header-tabs">
                <li className="nav-item">
                  <Link className="nav-link " to="/sallerdetails">
                    Shop Overview
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/sallerorder">
                    Order
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/sallerproducts">
                    Product
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sallersatting">
                    Setting
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active" to="/sallertransacation">
                    Transaction
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sallerreview">
                    Review
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="content container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="px-3 py-4">
                    <div class="row align-items-center">
                      <div class="col-lg-6 mb-3 mb-lg-0">
                        <h5 class="mb-0 text-capitalize d-flex gap-1 align-items-center">
                          Transaction data
                          <span class="badge badge-soft-dark fz-12">
                            {count}
                          </span>
                        </h5>
                      </div>
                      <div class="col-md-8 col-lg-4 mb-3 mb-md-0">
                        <form action="" method="GET">
                          <div class="input-group input-group-merge input-group-custom">
                            {" "}
                            <input
                              type="search"
                              name="searchValue"
                              className="form-control"
                              placeholder="Search by customer name"
                              aria-label="Search orders"
                              onChange={handleFilter}
                            />
                            <button type="submit" class="btn btn--primary">
                              Search
                            </button>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-4 col-lg-2">
                        <form method="GET">
                          <div class="d-flex justify-content-end align-items-center gap-10">
                            <button
                              onClick={handleExport}
                              type="button"
                              className="btn btn-outline--primary"
                              data-toggle="dropdown"
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>{" "}
                              Export
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {filteredSellerList.length > 0 ? (
                      <table
                        style={{ textAlign: "left" }}
                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                      >
                        <thead className="thead-light thead-50 text-capitalize">
                          <tr>
                            <th>SL</th>
                            <th>Shop name</th>
                            <th>Customer name</th>
                            <th>Order id</th>
                            <th>Order Status</th>

                            <th>Transaction id</th>
                            <th>Order amount</th>
                            <th>Vendor amount</th>
                            <th>Admin commission</th>
                            {/* <th>Received by</th> */}
                            <th>Delivered by</th>
                            <th>Delivery charge</th>
                            <th>Payment method</th>
                            <th>Tax</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredSellerList
                            .slice(
                              (activePage - 1) * itemsPerPage,
                              activePage * itemsPerPage
                            )
                            .map((seller, index) =>
                              renderSellerData(seller, index)
                            )}
                        </tbody>
                      </table>
                    ) : (
                      <div class="text-center p-4">
                        <img
                          class="mb-3 w-160"
                          src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                          alt="Image Description"
                        />
                        <p class="mb-0 order-stats__subtitle">No data found</p>
                      </div>
                    )}
                    <div className="d-flex justify-content-center mt-4">
                      {filteredSellerList.length > itemsPerPage && (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={itemsPerPage}
                          totalItemsCount={filteredSellerList.length}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      )}
                    </div>
                  </div>
                  <div className="table-responsive mt-4">
                    <div className="px-4 d-flex justify-content-center justify-content-md-end"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sallertransacations;
