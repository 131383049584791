import React, { useEffect, useState } from "react";
import "../sidebar.css";
import Header from "../Header";
import swal from "sweetalert";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Updateattribute = () => {
  const Navigate = useNavigate();
  const [name, setname] = useState();
  const [values, setvalues] = useState();

  let attributeid = secureLocalStorage.getItem("attributeid");
  let cateid = secureLocalStorage.getItem("categoryIdd")
  
  useEffect(() => {
    getdetails();
  }, [0]);
  const getdetails = () => {
    const data = {
      attributeId: attributeid,
    };
    axios
      .post(`${process.env.REACT_APP_API_KEY}admin/api/get_attribute`, data)
      .then((res) => {
        setname(res?.data?.data[0]?.attribute_name);
        setvalues(res?.data?.data[0]?.attribute_values);
     
      })
      .catch((error) => {});
  };

  const attributedata = (e) => {
    e.preventDefault();
    const data = {
      attributeId: attributeid,
      categoryId:cateid,
      
      attribute_name: name,
      attribute_values: values,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}admin/api/updateAttributes`, data)
      .then((res) => {
        toast.success(res?.data?.message);
        setTimeout(()=>{
          Navigate("/Add Attribute")
        },3000)
      })
      .catch((error) => {});
  };
  return (
    <div>
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-3 col-md-4">{/* <Sidebarr /> */}</div>

        <div className="col-lg-9 col-md-8" style={{ marginTop: "60px" }}>
          <div className="mt-3 mb-5">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img
                  width={20}
                  src="img/self-development.png"   alt=""
              />
              Update attribute
            </h2>
          </div>
          <div className="row mb-5">
            <div className="col-md-12 mb-10">
              <div className="card" style={{borderColor:'rgb(188, 189, 190)'}}>
                <div className="card-body text-start">
                  <form onSubmit={attributedata}>
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="HcBw85AVMU3NlW0FxSVZzbDgSe6986bbasNIVSVk"
                      autoComplete="off"
                    />

                    <div
                      className="form-group  form-system-language-form"
                      id="en-form"
                    >
                      <input type="hidden" id="id" />
                      <label className="title-color" htmlFor="name">
                        Attribute Name Attribute Name
                      </label>
                      <input
                        onChange={(e) => {
                          setname(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        value={name}
                      />
                    </div>
                    <div
                      className="form-group  form-system-language-form"
                      id="en-form"
                    >
                      <input type="hidden" id="id" />
                      <label className="title-color" htmlFor="name">
                        Attribute Name Attribute Values
                      </label>
                      <input
                        required
                        onChange={(e) => {
                          setvalues(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        value={values}
                      />
                    </div>

                    <input
                      type="hidden"
                      name="lang[]"
                      defaultValue="in"
                      id="lang"
                    />
                    <div className="d-flex justify-content-end gap-3">
                      <button
                        onClick={() => {
                          Navigate("/Add Attribute");
                        }}
                        type="reset"
                        className="btn px-4 btn-secondary"
                      >
                        Back
                      </button>
                      <button type="submit" className="btn px-4 btn--primary">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Updateattribute;
