import React, { useEffect, useState } from "react";
import "../sidebar.css";
import Header from "../Header";
import swal from "sweetalert";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "react-js-pagination";
const Coupan = () => {
  const [categoryidd, setcategoryidd] = useState();
  const [sizename, setsizename] = useState([]);

  const [sizeelist, setsizeelist] = useState();
  const [filteredsizeList, setfilteredsizeList] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const [count, setcount] = useState();
  const [categorylist, setcategorylist] = useState();
  const [Attributename, setAttributename] = useState();
  const Navigate = useNavigate();
  let token = secureLocalStorage.getItem("adminidtoken");

  useEffect(() => {
    setfilteredsizeList(sizeelist);
  }, [sizeelist]);

  useEffect(() => {
    getcategorlist();
  }, [0]);
  let getcategorlist = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}admin/api/getCategory`)
      .then((res) => {
        const filteredData = res?.data?.data?.filter(
          (item) => item?.maincategoryId?.maincategory_englishName !== "InntOut"
        );

        setcategorylist(filteredData);
        // setcategorylist(res.data.data);
      })
      .catch((error) => {});
  }; 

  let deleteproducts = (item) => {   
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this attribute file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletebannerimage = () => {
          let bannerdata = {
            attributeId: item,
          };

          let options = {
            headers: {
              token: token,
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API_KEY}admin/api/deleteAttributes`,
              bannerdata,
              options
            )
            .then((res) => {
              getsizeelist();
            })
            .catch((error) => {});
        };
        deletebannerimage();
        swal("Poof! Your attribute file has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your attribute is safe!");
      }
    });
  };
  const addsize = (e) => {
    e.preventDefault();

    const formData = {
      categoryId: categoryidd,
      attribute_name: Attributename,
      attribute_values: sizename,
    };

    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}admin/api/insertAttributes`,
        formData,
        options
      )
      .then((res) => {
        toast.success(res.data.message);

        getsizeelist();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      });

    setcategoryidd("");
    setsizename("");
    setAttributename("");
  };

  useEffect(() => {
    getsizeelist();
  }, [0]);
  let getsizeelist = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}admin/api/attributes_list`)
      .then((res) => {
        setcount(res?.data?.data?.length);
        setsizeelist(res.data.data);
      })
      .catch((error) => {});
  };

  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const result = sizeelist.filter((item) =>
      item?.attribute_name?.toLowerCase().includes(searchTerm)
    );
    setfilteredsizeList(result);
    setActivePage(1);
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const renderColorData = (data, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr key={index}>
        <td>{adjustedIndex}</td>

        <td className="text-capitalize" style={{ whiteSpace: "break-spaces" }}>
          {data?.attribute_values?.join(", ")}
          {/* {data?.color.join(", ")} */}
        </td>
        <td className="text-center text-capitalize">{data?.attribute_name}</td>
        <td className="text-center text-capitalize">
          {data?.categoryId?.category_englishName}
        </td>
        <td className="text-center text-capitalize">{data?.categoryId?.category_frenchName}</td>

        <td>
          <div className="d-flex justify-content-center gap-2">
            <span
              className="btn btn-outline--primary btn-sm cursor-pointer edit"
              onClick={() => {
                secureLocalStorage.setItem("attributeid", data?._id);
                secureLocalStorage.setItem("categoryIdd", data?.categoryId);
                Navigate("/updateattribute");
              }}
              title="Edit"
            >
              <i className="fa fa-pencil-square-o" aria-hidden="true" />
            </span>
            <a
              onClick={() => {
                deleteproducts(data?._id);
              }}
              className="btn btn-outline-danger btn-sm brand-delete-button square-btn"
              title="Delete"
              id={1}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </a>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div>
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-3 col-md-4">{/* <Sidebarr /> */}</div>

        <div className="col-lg-9 col-md-8" style={{ marginTop: "60px" }}>
          <div className="mt-3 mb-5">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img
                width={20}
                src="img/self-development.png"  alt=""
              />
              Attribute Setup
              <span className="badge badge-soft-dark radius-50 fz-12">
                {count}
              </span>
            </h2>
          </div>
          <div className="row mt-4 ">
            <div className="col-md-12">
              <div className="card mb-3" style={{borderColor:'rgb(188, 189, 190)'}}>
                <div className="card-body text-start">
                  <form onSubmit={addsize}>
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="LOivn3m2NU0upgIv2pBhjrFyz9MIByXFPsdKT17p"
                      autoComplete="off"
                    />

                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="form-group  form-system-language-form"
                          id="en-form"
                        >
                          <label htmlFor="name" className="title-color">
                            Category Name
                            <span className="text-danger">*</span>
                            {/* (EN) */}
                          </label>

                          <select
                            required
                            className="js-example-responsive form-control w-100"
                            value={categoryidd}
                            onChange={(e) => {
                              setcategoryidd(e.target.value);
                            }}
                          >
                            <option value="" selected disabled>
                              Select Category
                            </option>

                            {categorylist?.map((type) => (
                              <option key={type?._id} value={type?._id}>
                                {type?.category_englishName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="form-group  form-system-language-form"
                          id="en-form"
                        >
                          <label htmlFor="name" className="title-color">
                            Attribute Name
                            <span className="text-danger">*</span>
                          </label>

                          <input
                            type="text"
                            required
                            placeholder="Attribute Name"
                            className="js-example-responsive form-control w-100"
                            value={Attributename}
                            onChange={(e) => {
                              setAttributename(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="form-group  form-system-language-form"
                          id="en-form"
                        >
                          <label htmlFor="name" className="title-color">
                            Enter Values(Comma seperate )
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            value={sizename}
                            onChange={(e) => {
                              setsizename(e.target.value);
                            }}
                            type="text"
                            name="name[]"
                            className="form-control"
                            id="name"
                            placeholder="Enter Values"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                      {/* <button type="reset" id="reset" className="btn btn-secondary px-4">Reset</button> */}
                      <button type="submit" className="btn btn--primary px-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row mt-20 mb-5">
                <div className="col-md-12">
                  <div className="card">
                    <div class="px-3 py-4">
                      <div class="row align-items-center">
                        <div class="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                          <h5 class="mb-0 d-flex align-items-center gap-2">
                            Attribute list
                            <span class="badge badge-soft-dark radius-50 fz-12">
                              {count}
                            </span>
                          </h5>
                        </div>
                        <div class="col-sm-8 col-md-6 col-lg-4">
                          <form
                            action="https://6valley.6amtech.com/admin/attribute/view"
                            method="GET"
                          >
                            <div class="input-group input-group-custom input-group-merge">
                              <div class="input-group-prepend">
                                <div class="input-group-text">
                                  <i class="tio-search"></i>
                                </div>
                              </div>
                              <input
                                onChange={handleFilter}
                                type="search"
                                name="searchValue"
                                class="form-control"
                                placeholder="Search by Attribute Name"
                                aria-label="Search orders"
                                required=""
                              />
                              <button type="submit" class="btn btn--primary">
                                Search
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        {filteredsizeList?.length > 0 ? (
                          <table
                            style={{ textAlign: "left" }}
                            className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                          >
                            <thead className="thead-light thead-50 text-capitalize">
                              <tr>
                                <th>SL</th>

                                <th>Attribute Values</th>
                                <th className="text-center">Attribute Name</th>
                                <th className="text-center">
                                  Category Name(EN)
                                </th>
                                <th className="text-center">
                                  Category Name(FR)
                                </th>

                                <th className="text-center"> Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredsizeList
                                .slice(
                                  (activePage - 1) * itemsPerPage,
                                  activePage * itemsPerPage
                                )
                                .map((sizeelist, index) =>
                                  renderColorData(sizeelist, index)
                                )}
                            </tbody>
                          </table>
                        ) : (
                          <div class="text-center p-4">
                            <img
                              class="mb-3 w-160"
                              src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                              alt="Image Description"
                            />
                            <p class="mb-0 order-stats__subtitle">
                              No data found
                            </p>
                          </div>
                        )}
                        <div className="d-flex justify-content-center mt-4">
                          {filteredsizeList?.length > itemsPerPage && (
                            <Pagination
                              activePage={activePage}
                              itemsCountPerPage={itemsPerPage}
                              totalItemsCount={filteredsizeList.length}
                              pageRangeDisplayed={5}
                              onChange={handlePageChange}
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive mt-4">
                      <div className="d-flex justify-content-lg-end"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupan;
