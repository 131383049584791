import React, { useEffect, useState } from "react";
import Header from "../Header";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "react-js-pagination";
import * as XLSX from "xlsx";
const Cetagory = () => {
  let navigate = useNavigate();

  const [mainCategory_name, setmaincategory] = useState();
  const [category_englishName, setcategory_englishName] = useState();
  const [category_frenchName, setcategory_frenchName] = useState();
  const [category_image, setcategory_image] = useState();
  const [maincategoryid, setmaincategoryid] = useState();
  const [categorylist, setcategorylist] = useState([]);

  const [filteredCategoryList, setFilteredCategoryList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const [mainCategory_list, setmainCategory_list] = useState();
  const [count, setcount] = useState();
  let token = secureLocalStorage.getItem("adminidtoken");

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(categorylist);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Category List");

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");

    if (document.body) {
      document.body.appendChild(link);

      link.setAttribute("href", url);
      link.setAttribute("download", "categorylist.xlsx");

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
    }
  };
  let deletebanner = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this banner?",
      icon: "success",
      dangerMode: true,
    });
  };
  useEffect(() => {
    setFilteredCategoryList(categorylist);
  }, [categorylist]);
  let addcategorydata = () => {
    swal({
      title: "Category added Successfully",
      text: "Category inserted sucessfully",
      icon: "success",
      buttons: true,
    });
  };

  const categorydatahandle = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("mainCategory_name", mainCategory_name);
    formData.append("maincategoryId", maincategoryid);
    formData.append("category_englishName", category_englishName);
    formData.append("category_frenchName", category_frenchName);
    formData.append("category_image", category_image);

    // let options = {
    //   headers: {
    //     token: token,
    //   },
    // };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}admin/api/createCategory`,
        formData
      )
      .then((res) => {
        addcategorydata();
        getbannerlist();
      })
      .catch((error) => {});
    setmaincategory("");
    setcategory_englishName("");
    setcategory_frenchName("");
    setcategory_image("");
  };


  const [valueof, setvalueof] = useState();
  useEffect(() => {
    getbannerlist();
  }, [valueof]);

  let getbannerlist = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}admin/api/getCategory`)
      .then((res) => {
        console.log("res",res)
        let filteredData = [];

        if (valueof === "Innt") {
       

          filteredData = res?.data?.data?.filter(
            (item) => item?.maincategoryId?.maincategory_englishName === "Innt"
          );
        } else if (valueof === "InntOut") {
         
          filteredData = res?.data?.data?.filter(
            (item) => item?.maincategoryId?.maincategory_englishName === "InntOut"

          );
        
        }
         else {
          filteredData = res?.data?.data;
        }

        setcount(filteredData?.length);
        setcategorylist(filteredData);
       
      })
      .catch((error) => {});
  };

  const handleFilter = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const result = categorylist.filter(
      (item) =>
        item.category_englishName?.toLowerCase().includes(searchTerm) ||
        item.category_frenchName?.toLowerCase().includes(searchTerm) ||
        item.maincategoryId?.maincategory_englishName
          ?.toLowerCase()
          .includes(searchTerm)
    );
    setFilteredCategoryList(result);
    setActivePage(1);
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    mainCategorylist();
  }, [0]);
  let mainCategorylist = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}admin/api/mainCategory_list`)
      .then((res) => {
        setmainCategory_list(res.data.data);
      })
      .catch((error) => {});
  };

  let cetagoryedit = () => {
    navigate("/editcetagory");
  };

  let deletesubcategory = (item) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletebannerimage = () => {
          let bannerdata = {
            categoryId: item,
          };

          let options = {
            headers: {
              token: token,
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API_KEY}admin/api/deleteCategory`,
              bannerdata,
              options
            )
            .then((res) => {
              getbannerlist();
            })
            .catch((error) => {});
        };
        deletebannerimage();
        swal("Poof! Your category  has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your category is safe!");
      }
    });
  };

  const activedeactive = (item) => {
    const Data = {
      categoryId: item,
    };
    let options = {
      hraders: {
        token: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}admin/api/activeDeactive_category`,
        Data,
        options
      )
      .then((res) => {
        toast.success(res.data.message);
        getbannerlist();
      })
      .catch((error) => {});
  };

  const renderCategoryData = (categorydata, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr key={index}>
        <td>{adjustedIndex}</td>
        <td className="text-center">
          <img
            className="rounded"
            width={64}
            src={
              `${process.env.REACT_APP_API_KEY}uploads/` +
              categorydata?.category_image
            }
          />
        </td>
        <td className="text-capitalize">
          {categorydata?.maincategoryId?.maincategory_englishName}
        </td>
        <td className="text-capitalize">{categorydata?.category_frenchName}</td>
        <td className="text-capitalize">
          {categorydata?.category_englishName}
        </td>

        {categorydata?.acrtive_status != 0 ? (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher">
                <input
                  type="checkbox"
                  className="switcher_input"
                  name="status"
                  onChange={() => activedeactive(categorydata?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        ) : (
          <td>
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher">
                <input
                  id="coupon_status9"
                  name="status"
                  defaultValue={1}
                  defaultChecked
                  type="checkbox"
                  className="switcher_input"
                  onChange={() => activedeactive(categorydata?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        )}
        <td>
          <div className="d-flex gap-10 justify-content-center">
            <span
              className="btn btn-outline--primary btn-sm cursor-pointer edit"
              onClick={() => {
                cetagoryedit(
                  secureLocalStorage.setItem("categoryid", categorydata?._id)
                );
              }}
              title="Edit"
            >
              <i className="fa fa-pencil-square-o" aria-hidden="true" />
            </span>
            <a
              onClick={() => {
                deletesubcategory(categorydata?._id);
              }}
              className="btn btn-outline-danger btn-sm cursor-pointer delete"
              title="Delete"
              id={35}
            >
              <i
                className="fa fa-trash-o"
                onClick={deletesubcategory}
                aria-hidden="true"
              />
            </a>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div>
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-3 col-md-4" style={{ paddingLeft: "0px" }}>
          {/* <Sidebarr /> */}
        </div>

        <div
          className="col-lg-9 col-md-8"
          style={{ paddingLeft: "0px", marginTop: "60px" }}
        >
          <div className="mt-3">
            <div className="mb-3">
              <h2 className="h1 mb-0 d-flex gap-10">
                <img width={20} src="img/menu.png" alt />
                Category
              </h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body" style={{ textAlign: "left" }}>
                    <form onSubmit={categorydatahandle}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div>
                            <div className="form-group">
                              <label className="title-color">
                                Main category
                                {/* mainCategory_list */}
                              </label>
                              <select
                                required
                                value={maincategoryid}
                                onChange={(e) => {
                                  setmaincategoryid(e.target.value);
                                }}
                                className="form-control"
                              >
                                <option value="" disabled selected>
                                  Set Main category
                                </option>
                                {mainCategory_list?.map((data, index) => (
                                  <option key={index} value={data?._id}>
                                    {data?.maincategory_englishName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group  lang_form">
                              <label className="title-color">
                                Category Name
                                <span className="text-danger">*</span> (EN)
                              </label>
                              <input
                                value={category_englishName}
                                onChange={(e) => {
                                  setcategory_englishName(e.target.value);
                                }}
                                type="text"
                                name="name[]"
                                className="form-control"
                                placeholder="New Category"
                                required
                              />
                            </div>
                            <div className="form-group  lang_form">
                              <label className="title-color">
                                Category Name
                                <span className="text-danger">*</span> (FR)
                              </label>
                              <input
                                value={category_frenchName}
                                onChange={(e) => {
                                  setcategory_frenchName(e.target.value);
                                }}
                                type="text"
                                name="name[]"
                                className="form-control"
                                placeholder="New Category"
                                required
                              />
                            </div>
                          </div>

                          <div className="from_part_2">
                            <label className="title-color">Category Logo</label>
                            <span className="text-info">
                              <span className="text-danger">*</span>
                              {/* Ratio 1:1 (500 x 500 px) */}
                            </span>
                            <div className="custom-file text-left">
                              <input
                                onChange={(e) => {
                                  setcategory_image(e.target.files[0]);
                                }}
                                type="file"
                                name="image"
                                className="custom-file-input"
                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                required
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileEg1"
                              >
                                Choose File
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                          <div className="form-group">
                            <center>
                              {category_image ? (
                                <img
                                  className="upload-img-view"
                                  src={URL.createObjectURL(category_image)}
                                  alt="image"
                                />
                              ) : (
                                <img
                                  className="upload-img-view"
                                  id="viewer"
                                  src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                  alt="image"
                                />
                              )}
                            </center>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        {/* <button
                          type="reset"
                          id="reset"
                          className="btn btn-secondary"
                        >
                          Reset
                        </button> */}
                        <button type="submit" className="btn btn--primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-20" id="cate-table">
              <div className="col-md-12">
                <div className="card">
                  <div className="px-3 py-4">
                    <div className="d-flex justify-content-between gap-10 flex-wrap align-items-center">
                      <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                        <h5 className="text-capitalize d-flex gap-1">
                          Category list
                          <span className="badge badge-soft-dark radius-50 fz-12">
                            {count}
                          </span>
                        </h5>
                      </div>
                       <div className>
                        <form>
                          <div className="input-group input-group-merge input-group-custom">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i class="fa fa-search" aria-hidden="true"></i>
                              </div>
                            </div>
                            <input
                              type="search"
                              name="searchValue"
                              className="form-control"
                              placeholder="Search here...."
                              aria-label="Search orders"
                              onChange={handleFilter}
                            />
                            <button type="submit" className="btn btn--primary">
                              Search
                            </button>
                          </div>
                        </form>
                      </div> 
                     
                      <div className="d-flex justify-content-end gap-2">
                      <div className="d-flex col-auto gap-3">
                      <select
                        name="withdraw_status_filter"
                        onChange={(e) => {
                          setvalueof(e.target.value);
                        }}
                        className="custom-select min-w-120 withdraw-status-filter"
                      >
                        <option value="all">All</option>
                        <option value="InntOut">InntOut</option>
                        <option value="Innt">Innt</option>
                       
                      </select>
                    </div>
                        <div className="dropdown text-nowrap">
                          <button
                            onClick={handleExport}
                            type="button"
                            className="btn btn-outline--primary"
                            data-toggle="dropdown"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>{" "}
                            Export
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    {filteredCategoryList.length > 0 ? (
                      <table
                        style={{ textAlign: "left" }}
                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                      >
                        <thead className="thead-light thead-50 text-capitalize">
                          <tr>
                            <th>ID</th>
                            <th className="text-center"> Image</th>
                            <th>Main Category </th>

                            <th> Category (FR) </th>
                            <th> Category (EN) </th>

                            <th className="text-center"> Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredCategoryList
                            .slice(
                              (activePage - 1) * itemsPerPage,
                              activePage * itemsPerPage
                            )
                            .map((seller, index) =>
                              renderCategoryData(seller, index)
                            )}
                        </tbody>
                      </table>
                    ) : (
                      <div class="text-center p-4">
                        <img
                          class="mb-3 w-160"
                          src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                          alt="Image Description"
                        />
                        <p class="mb-0 order-stats__subtitle">
                          No Category found
                        </p>
                      </div>
                    )}
                    <div className="d-flex justify-content-center mt-4">
                      {filteredCategoryList.length > itemsPerPage && (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={itemsPerPage}
                          totalItemsCount={filteredCategoryList.length}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      )}
                    </div>
                  </div>
                  <div className="table-responsive mt-4">
                    <div className="d-flex justify-content-lg-end"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Cetagory;
