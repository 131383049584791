import React, { useEffect, useState } from "react";
import "../sidebar.css";
import Header from "../Header";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Withdrawviewdetails = () => {
  const [dataofwithdraw, setdataofwithdraw] = useState([]);

  let withdrawid = secureLocalStorage.getItem("withdrawids");

  useEffect(() => {
    getwithd();
  }, [0]);
  const getwithd = () => {
    const data = {
      transId: withdrawid,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}admin/api/transjectionDetails`,
        data
      )
      .then((res) => {
        setdataofwithdraw(res.data.data);
      })
      .catch((error) => {});
  };

  return (
    <div>
      <Toaster />
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-3 col-md-4">{/* <Sidebarr /> */}</div>

        <div className="col-lg-9 col-md-8" style={{ marginTop: "60px" }}>
          <div className="mt-3 mb-5">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img width={20} src="img/wallet.png" alt="" />
              Transaction
            </h2>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="card">
                <div className="card-body text-start">
                  <div className="text-capitalize d-flex align-items-center justify-content-between gap-2 border-bottom pb-2 mb-4">
                    <h3 className="text-capitalize">
                      Transaction Id {dataofwithdraw?.transjectionId}
                    </h3>
                    <i class="fa fa-credit-card fz-30" aria-hidden="true"></i>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-2 mb-md-0">
                      <div className="flex-start flex-wrap">
                        <div>
                          <h5 className="text-capitalize">Amount: </h5>
                        </div>
                        <div className="mx-1">
                          <h5>
                            {dataofwithdraw?.amount
                              ? dataofwithdraw?.amount
                              : dataofwithdraw?.total}{" "}
                            CFA
                          </h5>
                        </div>
                      </div>
                      <div className="flex-start flex-wrap">
                        <div>
                          <h5>Request time: </h5>
                        </div>
                        <div className="mx-1">
                          {dataofwithdraw?.userId?.createdAt.slice(0, 10)}{" "}
                          {dataofwithdraw?.userId?.createdAt.slice(11, 19)}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4 mb-2 mb-md-0">
                      <div className="flex-start">
                        <div className="title-color">
                          Note: Please Avvept my transication
                        </div>
                        <div className="mx-1" />
                      </div>
                    </div> */}
                    {
                      dataofwithdraw?.payment_status == "Transfer" ? (
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="btn btn-success float-end"
                          >
                            {dataofwithdraw?.payment_status} &nbsp;
                          </button>
                        </div>
                      ) : (
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="btn btn-danger float-end"
                          >
                            Order
                          </button>
                        </div>
                      )

                      //  : (
                      //   <div className="col-md-2">
                      //     <div className="text-center float-end">
                      //       <label className="btn btn-danger  p-2 rounded-bottom">
                      //         Denied
                      //       </label>
                      //     </div>
                      //   </div>
                      // )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body text-start">
                  <div className="text-capitalize d-flex align-items-center justify-content-between gap-2 border-bottom pb-3 mb-4">
                    <h3 className="h3 mb-0">
                      Customer info&nbsp;
                      {/* {dataofwithdraw[0]?.userId?.user_profile === " " ? (
                        <img
                          width={50}
                          className="avatar rounded-circle"
                          src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f140b5c50.png"
                          alt=""
                        />
                      ) : (
                        <img
                          className="avatar rounded-circle"
                          src={
                            `${process.env.REACT_APP_API_KEY}uploads/` +
                            dataofwithdraw[0]?.userId?.user_profile
                          }
                        />
                      )} */}
                    </h3>
                    <i class="fa fa-usd" aria-hidden="true"></i>
                  </div>
                  <div className="mt-2">
                    <div className="flex-start">
                      <div className="mt-3">
                        <h6>Profile : </h6>
                      </div>
                      <div className="mx-1">
                        <h6>
                          {dataofwithdraw?.userId?.user_profile === " " ||
                          dataofwithdraw?.userId?.user_profile === undefined ? (
                            <img
                              width={50}
                              className="avatar rounded-circle"
                              src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f140b5c50.png"
                              alt=""
                            />
                          ) : dataofwithdraw?.userId?.user_profile?.startsWith(
                              "https://"
                            ) ? (
                            <img
                              src={dataofwithdraw?.userId?.user_profile}
                              width={50}
                              className="avatar rounded-circle"
                              alt=""
                            />
                          ) : (
                            <img
                              src={
                                `${process.env.REACT_APP_API_KEY}uploads/` +
                                dataofwithdraw?.userId?.user_profile
                              }
                              width={50}
                              className="avatar rounded-circle"
                              alt=""
                            />
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="flex-start">
                      <div>
                        <h6>User Name : </h6>
                      </div>
                      <div className="mx-1">
                        <h6 className="text-capitalize">
                          {dataofwithdraw?.userId?.first_name}
                        </h6>
                      </div>
                    </div>

                    <div className="flex-start">
                      <div>
                        <h6>Email : </h6>
                      </div>
                      <div className="mx-1">
                        <h6 className="">{dataofwithdraw?.userId?.email}</h6>
                      </div>
                    </div>
                    <div className="flex-start">
                      <div>
                        <h6>Phone No. : </h6>
                      </div>
                      <div className="mx-1">
                        <h6>{dataofwithdraw?.userId?.mobile_number}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {dataofwithdraw?.shopId ? (
              <div className="col-md-4 mb-3">
                <div className="card h-100">
                  <div className="card-body text-start">
                    <div className="text-capitalize d-flex align-items-center justify-content-between gap-2 border-bottom pb-3 mb-4">
                      <h3 className="h3 mb-0">Vendor info </h3>
                      <i class="fa fa-stop-circle" aria-hidden="true"></i>
                    </div>
                    <div className="flex-start">
                      <div className="mt-3">
                        <h6>Shop Logo : </h6>
                      </div>
                      <div className="mx-1">
                        <h6>
                          {dataofwithdraw?.shopId &&
                          dataofwithdraw?.shopId?.length > 0 &&
                          dataofwithdraw?.shopId?.shop_logo ? (
                            <img
                              className="avatar rounded-circle"
                              src={`${process.env.REACT_APP_API_KEY}uploads/${dataofwithdraw?.shopId?.shop_logo}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="avatar rounded-circle"
                              src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f140b5c50.png"
                              alt=""
                            />
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="flex-start">
                      <div>
                        <h6>Shop Name : </h6>
                      </div>
                      <div className="mx-1">
                        <h6 className="text-capitalize">
                          {dataofwithdraw?.products?.[0]?.shop_name}
                        </h6>
                      </div>
                    </div>

                    <div className="flex-start">
                      <div>
                        <h6>Vendor Name : </h6>
                      </div>
                      <div className="mx-1">
                        <h6 className="text-capitalize">
                          {dataofwithdraw?.shopId?.firstName}
                        </h6>
                      </div>
                    </div>

                    <div className="flex-start">
                      <div>
                        <h6>Email : </h6>
                      </div>
                      <div className="mx-1">
                        <h6>{dataofwithdraw?.shopId?.email}</h6>
                      </div>
                    </div>

                    <div className="flex-start">
                      <div>
                        <h6>Mobile Money : </h6>
                      </div>
                      <div className="mx-1">
                        <h6>{dataofwithdraw?.shopId?.mobile_money_number}</h6>
                      </div>
                    </div>

                    <div className="flex-start">
                      <div>
                        <h6>Wallet Balance : </h6>
                      </div>
                      <div className="mx-1">
                        <h6>{dataofwithdraw?.shopId?.wallet} CFA</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {dataofwithdraw?.products ? (
              <div className="col-md-4 mb-3">
                <div className="card h-100">
                  <div className="card-body text-start">
                    <div className="text-capitalize d-flex align-items-center justify-content-between gap-2 border-bottom pb-3 mb-4">
                      <h3 className="h3 mb-0">Order Info </h3>
                      <i class="fa fa-user-o" aria-hidden="true"></i>
                    </div>

                    <div className="flex-start">
                      <div className="mt-3">
                        <h6>Product : </h6>
                      </div>
                      <div className="mx-1">
                        <h6>
                          {dataofwithdraw?.products &&
                          dataofwithdraw?.products?.length > 0 &&
                          dataofwithdraw?.products[0]?.image ? (
                            <img
                              className="avatar rounded-circle"
                              src={`${process.env.REACT_APP_API_KEY}uploads/${dataofwithdraw?.products[0]?.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="avatar rounded-circle"
                              src="https://6valley.6amtech.com/storage/app/public/shop/2022-04-21-6260f140b5c50.png"
                              alt=""
                            />
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="flex-start">
                      <div>
                        <h6>Product Name :</h6>
                      </div>
                      <div className="mx-1">
                        <h6 className="text-capitalize">
                          {dataofwithdraw?.products[0]?.product_name}
                        </h6>
                      </div>
                    </div>
                    <div className="flex-start">
                      <div>
                        <h6>Discount :</h6>
                      </div>
                      <div className="mx-1">
                        <h6>{dataofwithdraw?.products[0]?.discount} CFA</h6>
                      </div>
                    </div>
                    <div className="flex-start">
                      <div>
                        <h6>Qty :</h6>
                      </div>
                      <div className="mx-1">
                        <h6>{dataofwithdraw?.products[0]?.qty}</h6>
                      </div>
                    </div>

                    <div className="flex-start">
                      <div>
                        <h6>Shipping Charge :</h6>
                      </div>
                      <div className="mx-1">
                        <h6>{dataofwithdraw?.products[0]?.shipping_charge}</h6>
                      </div>
                    </div>
                    {dataofwithdraw?.products[0]?.attributes?.map((data) => {
                      return (
                        <div className="flex-start">
                          <div>
                            <h6>{data?.name} :</h6>
                          </div>
                          <div className="mx-1">
                            <h6>{data?.value}</h6>
                          </div>
                        </div>
                      );
                    })}

                    <div className="flex-start">
                      <div>
                        <h6>Total :</h6>
                      </div>
                      <div className="mx-1">
                        <h6>{dataofwithdraw?.products[0]?.total} CFA</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrawviewdetails;
