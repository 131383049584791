import React, { useEffect, useState } from "react";
import "../sidebar.css";
import Header from "../Header";
import swal from "sweetalert";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebarr from "../Sidebar";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Pagination from "react-js-pagination";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";
const Walletbonussetup = () => {
  const [status, setstatus] = useState();
  const [count, setcount] = useState();
  const [sellerlist, setSellerlist] = useState([]);
  const [filteredSellerList, setFilteredSellerList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const Navigate = useNavigate();
  let token = secureLocalStorage.getItem("adminidtoken");

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(sellerlist);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Staff List");

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");

    if (document.body) {
      document.body.appendChild(link);

      link.setAttribute("href", url);
      link.setAttribute("download", "staff.xlsx");

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
    }
  };

  let vendoractive = (item) => {
    let sellerdata = {
      staffId: item,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}admin/api/staff_active_deactive`,
        sellerdata
      )
      .then((res) => {
        getsellerlist();
        toast.success(res.data.message);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getsellerlist();
  }, [0]);

  let getsellerlist = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}admin/api/staffList`)
      .then((res) => {

        setcount(res?.data?.data?.length);
        setSellerlist(res.data.data);
        setFilteredSellerList(res.data.data);
      })
      .catch((error) => {});
  };

  const handleFilter = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const result = sellerlist.filter((item) =>
      item.first_name?.toLowerCase().includes(searchTerm)
    );
    setFilteredSellerList(result);
    setActivePage(1);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderSellerData = (seller, index) => {
    const adjustedIndex = (activePage - 1) * itemsPerPage + index + 1;
    return (
      <tr>
        <td>{adjustedIndex}</td>
        <td>
          <div className="media align-items-center gap-10">
            <div className="media-body text-capitalize">
              <Link
                to="#"
                title="Earning Statement"
                className="title-color hover-c1"
              >
                {seller?.first_name?.length > 10
                  ? seller?.first_name.slice(0, 10) + `...`
                  : seller?.first_name}
                {/* {seller?.fname} {seller?.lname} */}
              </Link>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex flex-column gap-1">
            <div>
              <a
                className="title-color hover-c1 text-wrap max-w-200"
                href={`mailto:${seller?.email}`}
              >
                <strong>{seller?.email}</strong>
              </a>
            </div>
          </div>
        </td>

        <td className="text-capitalize text-wrap max-w-200">{seller?.address}</td>
        <td className="text-center">
          {seller?.staff_status !== 0 ? (
            <label className="badge badge-danger cursor-pointer">
              Deactive
            </label>
          ) : (
            <label className="badge badge-success cursor-pointer">Active</label>
          )}
        </td>

        {seller?.staff_status !== 0 ? (
          <td className="text-center">
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher">
                <input
                  type="checkbox"
                  className="switcher_input"
                  name="status"
                  onChange={() => vendoractive(seller?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        ) : (
          <td className="text-center">
            <form className="banner_status_form">
              <input type="hidden" />
              <input type="hidden" name="id" />
              <label className="switcher">
                <input
                  id="coupon_status9"
                  name="status"
                  defaultValue={1}
                  defaultChecked
                  type="checkbox"
                  className="switcher_input"
                  onChange={() => vendoractive(seller?._id)}
                />
                <span className="switcher_control" />
              </label>
            </form>
          </td>
        )}
        <td>
          <div className="d-flex justify-content-center align-items-center gap-10">
            {/* <Link 
               data-toggle="modal"
                          data-target="#exampleModal"
              onClick={(e) => {
                secureLocalStorage.setItem("driveridd", seller?._id);
                secureLocalStorage.setItem("restr", seller?.restrictions);

                Navigate("/test")
              }}
              className="btn btn-outline--primary btn-sm edit"
              title="Edit"
              href="https://6valley.6amtech.com/admin/delivery-man/update/10"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </Link> */}

            <Link
              to="/allproductsmanage"
              onClick={(e) => {
                secureLocalStorage.setItem("driveriddss", seller?._id);
                secureLocalStorage.setItem("restr", seller?.restrictions);
                secureLocalStorage.setItem("firstname", seller?.first_name);
                secureLocalStorage.setItem("lastname", seller?.last_name);
                secureLocalStorage.setItem("email", seller?.email);
              }}
              className="btn btn-outline--primary btn-sm edit"
              title="Edit"
              href="https://6valley.6amtech.com/admin/delivery-man/update/10"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </Link>

            <form>
              <input
                type="hidden"
                name="_token"
                defaultValue="5201ifIgVXHshEaS9xR5L76fdJ1eBw8H5dYCuDvv"
                autoComplete="off"
              />{" "}
              <input type="hidden" name="_method" defaultValue="delete" />{" "}
            </form>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div>
      {/* <Header /> */}
      <Toaster />
      <div
        className="container row"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          marginLeft: "0px",
        }}
      >
        <div className="col-lg-3 col-md-4">{/* <Sidebarr /> */}</div>

        <div className="col-lg-9 col-md-8" style={{ marginTop: "60px" }}>
          <div className="mt-3 mb-5">
            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
              <img width={20} src="img/staff.png" alt="" />
              Staff List
              <span className="badge badge-soft-dark radius-50 fz-12">
                {count}
              </span>
            </h2>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="card mb-3">
                <div className="px-3 py-4">
                  <div className="d-flex justify-content-between gap-10 flex-wrap align-items-center">
                    <div className>
                      <form>
                        <div className="input-group input-group-merge input-group-custom">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i class="fa fa-search" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input
                            type="search"
                            name="searchValue"
                            className="form-control"
                            placeholder="Search by name"
                            aria-label="Search orders"
                            onChange={handleFilter}
                          />
                          <button type="submit" className="btn btn--primary">
                            Search
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                      <div className="dropdown text-nowrap">
                        <button
                          onClick={handleExport}
                          type="button"
                          className="btn btn-outline--primary"
                          data-toggle="dropdown"
                        >
                          <i class="fa fa-download" aria-hidden="true"></i>{" "}
                          Export
                        </button>
                      </div>
                      <Link
                        to="/Add New Staff"
                        type="button"
                        className="btn btn--primary text-nowrap"
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i> Add New
                        Staff
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  {filteredSellerList?.length > 0 ? (
                    <table
                      style={{ textAlign: "left" }}
                      className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                    >
                      <thead className="thead-light thead-50 text-capitalize">
                        <tr>
                          <th>SL</th>
                          <th>Name</th>
                          <th>Contact info</th>
                          <th>Address</th>

                          <th className="text-center">Status</th>
                          <th>Action</th>
                          <th className="text-center">Update</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSellerList
                          .slice(
                            (activePage - 1) * itemsPerPage,
                            activePage * itemsPerPage
                          )
                          .map((seller, index) =>
                            renderSellerData(seller, index)
                          )}
                      </tbody>
                    </table>
                  ) : (
                    <div class="text-center p-4">
                      <img
                        class="mb-3 w-160"
                        src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                        alt="Image Description"
                      />
                      <p class="mb-0 order-stats__subtitle">No data found</p>
                    </div>
                  )}
                  <div className="d-flex justify-content-center mt-4">
                    {filteredSellerList?.length > itemsPerPage && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={filteredSellerList?.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </div>
                </div>
                <div className="table-responsive mt-4">
                  <div className="px-4 d-flex justify-content-center justify-content-md-end"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Withdraw request process
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form>
              <input
                type="hidden"
                name="_token"
                defaultValue="5201ifIgVXHshEaS9xR5L76fdJ1eBw8H5dYCuDvv"
                autoComplete="off"
              />{" "}
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Request:
                  </label>
                  <select required name="approved" className="custom-select">
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value={1}>Approve</option>
                    <option value={2}>Deny</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="message-text" className="col-form-label">
                    Note about transaction or request:
                  </label>
                  <textarea
                    className="form-control"
                    name="note"
                    id="message-text"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn--primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Walletbonussetup;
