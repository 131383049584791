import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import "./manubar.css";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  MdFeedback,
  MdLocalOffer,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { MdDiscount } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { MdPayments } from "react-icons/md";
import { MdSubscriptions } from "react-icons/md";
import { MdReviews } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { FaQuestionCircle, FaRocketchat } from "react-icons/fa";
import { BiCategory, BiMoneyWithdraw } from "react-icons/bi";
import { CiBank } from "react-icons/ci";
import { FaShoppingBag } from "react-icons/fa";
import { TbBrandProducthunt } from "react-icons/tb";
import { TbTruckDelivery } from "react-icons/tb";
import { PiFlagBannerFill } from "react-icons/pi";
import { IoChatboxEllipses } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { MdOutlineBrandingWatermark } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import secureLocalStorage from "react-secure-storage";

const Sidebarr = (props) => {
  const [data, setData] = useState({});
  let navigate = useNavigate();
  const [randomResult, setRandomResult] = useState("hello");

  let [opcount, setopcount] = useState();
  let manubaricon = () => {
    setopcount(1);
  };

  let manubarcrossicon = () => {
    setopcount(0);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  
  useEffect(() => {
    updateScreenWidth();

    
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, [screenWidth]); 

 
  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);

    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, [screenWidth]); 

  const handleDropdownChange = (event) => {
    let linkvalue = event.target.value;

    if (linkvalue === "message") {
      navigate("/message");
    } else if (linkvalue === "chatdeliveryman") {
      navigate("/chatdeliveryman");
    } else if (linkvalue === "chatwithadmin") {
      navigate("/chatwithadmin");
    }
  };

  const [sidebarStatus, setSidebarStatus] = useState(() => {
    return localStorage.getItem("setstatus");
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setSidebarStatus(localStorage.getItem("setstatus"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    setData(secureLocalStorage.getItem("roue"));
  }, []);

  const iconMapping = {
    FaUser: FaUser,
    MdOutlineBrandingWatermark: MdOutlineBrandingWatermark,
    FaShoppingBag: FaShoppingBag,
    TbTruckDelivery: TbTruckDelivery,
    BiCategory: BiCategory,
    TbBrandProducthunt: TbBrandProducthunt,
    MdLocalOffer: MdLocalOffer,
    IoChatboxEllipses: IoChatboxEllipses,
    PiFlagBannerFill: PiFlagBannerFill,
    IoMdNotifications: IoMdNotifications,
    MdReviews: MdReviews,
    BiMoneyWithdraw: BiMoneyWithdraw,
    IoMdSettings: IoMdSettings,
  };
  const storedIconNames = secureLocalStorage?.getItem("iconss") || [];
  const sunmenuiconss = storedIconNames?.map((name) => iconMapping[name]);

  // const sunmenuiconss = [
  //   FaUser,
  //   MdOutlineBrandingWatermark,
  //   FaShoppingBag,
  //   TbTruckDelivery,
  //   BiCategory,
  //   TbBrandProducthunt,
  //   TbBrandProducthunt,
  //   TbBrandProducthunt,
  //   MdLocalOffer,
  //   IoChatboxEllipses,
  //   PiFlagBannerFill,
  //   TbBrandProducthunt,
  //   IoMdNotifications,
  //   IoChatboxEllipses,
  //   MdReviews,
  //   BiMoneyWithdraw,

  //   IoMdSettings
  // ];
  const renderMenuItems = (items, basePath) => {
    return items?.map((item) => (
      <MenuItem
        onClick={() => {
          const currentStatus = localStorage.getItem("setstatus");
          const newStatus = currentStatus === "true" ? "false" : "true";
          localStorage.setItem("setstatus", newStatus);
          window.dispatchEvent(new Event("storage"));
        }}
        key={item}
        component={<Link to={`${basePath}${item}`} />}
        icon={
          <i
            class="fa fa-circle"
            style={{ color: "white", fontSize: "5px" }}
            aria-hidden="true"
          ></i>
        }
        style={{
          paddingLeft: "20px",
          backgroundColor: "#073b74",
          color: "white",
          height: "32px",
        }}
      >
        {item.replace("/", "").replace(/_/g, " ")}
      </MenuItem>
    ));
  };

  const renderDynamicSubMenus = (routes) => {
    return Object?.keys(routes).map((key, index) => (
      <SubMenu
        key={key}
        label={key.toUpperCase()}
        style={{
          paddingLeft: "5px",
          backgroundColor: "#073b74",
          color: "white",
          height: "39px",
        }}
        icon={React?.createElement(
          sunmenuiconss[index % sunmenuiconss?.length],
          { style: { fontSize: "20px" } }
        )}
      >
        {renderMenuItems(routes[key], "")}
      </SubMenu>
    ));
  };
  return (
    <div>
      {screenWidth >= 767 ? (
        <aside
          className="sidenav"
          style={{ textAlign: "left", paddingLeft: "0px", marginTop: "60px" }}
        >
          <div className="navbar-vertical-container ">
            <div className="navbar-vertical-footer-offset pb-0">
              <div className="">
                <ul
                  className="navbar-nav navbar-nav-lg nav-tabs pb-10"
                  style={{ borderBottom: "none" }}
                >
                  <li className="navbar-vertical-aside-has-menu ">
                    <Sidebar className="bg-info example">
                      <Menu style={{ width: "100%" }}>
                        <MenuItem
                          component={<Link to="/home" />}
                          icon={
                            <i
                              class="fa fa-home"
                              style={{ color: "white", fontSize: "20px" }}
                              aria-hidden="true"
                            ></i>
                          }
                          // icon={<BiCategory style={{ fontSize: "20px" }} />}
                          style={{
                            paddingLeft: "7px",
                            backgroundColor: "#073b74",
                            color: "white",
                            textTransform: "uppercase",
                          }}
                        >
                          {" "}
                          Dashboard{" "}
                        </MenuItem>
                        {renderDynamicSubMenus(data)}
                      </Menu>
                    </Sidebar>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
      ) : null}

      {sidebarStatus == "true" ? (
        <aside
          className="sidenav1"
          style={{
            textAlign: "left",
            paddingLeft: "0px",
            marginTop: "60px",
            opacity: `${opcount}`,
          }}
        >
          <div className="navbar-vertical-container ">
            <div className="navbar-vertical-footer-offset pb-0">
              <div className="">
                <ul
                  style={{
                    overflow: "scroll",
                    height: "450px",
                    borderBottom: "none",
                  }}
                  className="navbar-nav navbar-nav-lg nav-tabs pb-10"
                >
                  <li className="navbar-vertical-aside-has-menu ">
                    <Sidebar className="bg-info example">
                      <Menu style={{ width: "100%" }}>
                        <MenuItem
                          onClick={() => {
                            const currentStatus =
                              localStorage.getItem("setstatus");
                            const newStatus =
                              currentStatus === "true" ? "false" : "true";
                            localStorage.setItem("setstatus", newStatus);
                            window.dispatchEvent(new Event("storage"));
                          }}
                          component={<Link to="/home" />}
                          icon={
                            <i
                              class="fa fa-home"
                              style={{ color: "white", fontSize: "20px" }}
                              aria-hidden="true"
                            ></i>
                          }
                          style={{
                            paddingLeft: "7px",
                            backgroundColor: "#073b74",
                            color: "white",
                            textTransform: "uppercase",
                          }}
                        >
                          {" "}
                          Dashboard{" "}
                        </MenuItem>
                        {renderDynamicSubMenus(data)}
                      </Menu>
                    </Sidebar>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
      ) : null}
    </div>
  );
};

export default Sidebarr;
