import React, { useEffect, useState } from "react";
import "../sidebar.css";
import Header from "../Header";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Sidebarr from "../Sidebar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import * as XLSX from "xlsx";
const Loyaltyreport = () => {
  const [loyalty, setloyalty] = useState();
  const [count, setcount] = useState();
  useEffect(() => {
    getloyalty();
  },[0]);
  const getloyalty = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}admin/api/loyality_list`)
      .then((res) => {
        setloyalty(res.data.data);
        setcount(res?.data?.data?.length);
      })
      .catch((error) => {});
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(loyalty);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Loyalty List");

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");

    if (document.body) {
      document.body.appendChild(link);

      link.setAttribute("href", url);
      link.setAttribute("download", "loyaltylist.xlsx");

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
    }
  };
  return (
    <div>
      {/* <Header /> */}
      <div
        className="container row"
        style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      >
        <div className="col-lg-3 col-md-4">{/* <Sidebarr /> */}</div>

        <div className="col-lg-9 col-md-8" style={{ marginTop: "60px" }}>
          <div className="mt-3 mb-5">
            <div className="mb-3 d-flex justify-content-between flex-wrap gap-3">
              <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                <img width={20} src="img/customer-service.png" alt="" />
                Customer Loyalty Point Report
              </h2>
            </div>
            {/* <div
              className="modal fade"
              id="add-fund-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header d-flex justify-content-between">
                    <h5
                      className="modal-title text-capitalize"
                      id="exampleModalLongTitle"
                    >
                      Add fund
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="5201ifIgVXHshEaS9xR5L76fdJ1eBw8H5dYCuDvv"
                        autoComplete="off"
                      />{" "}
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <div className="form-group">
                            <label
                              className="input-label d-flex"
                              htmlFor="customer"
                            >
                              Customer
                            </label>
                            <select
                              id="form-customer"
                              name="customer_id"
                              data-placeholder="Select customer"
                              className="get-customer-list-by-ajax-request select2-hidden-accessible"
                              required
                              data-select2-id="form-customer"
                              tabIndex={-1}
                              aria-hidden="true"
                            >
                              <option value disabled data-select2-id={2}>
                                Select your option
                              </option>
                            </select>
                            <span
                              className="select2 select2-container select2-container--default"
                              dir="ltr"
                              data-select2-id={1}
                              style={{ width: "auto" }}
                            >
                              <span className="selection">
                                <span
                                  className="select2-selection select2-selection--single"
                                  role="combobox"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabIndex={0}
                                  aria-disabled="false"
                                  aria-labelledby="select2-form-customer-container"
                                >
                                  <span
                                    className="select2-selection__rendered"
                                    id="select2-form-customer-container"
                                    role="textbox"
                                    aria-readonly="true"
                                  >
                                    <span className="select2-selection__placeholder">
                                      Select customer
                                    </span>
                                  </span>
                                  <span
                                    className="select2-selection__arrow"
                                    role="presentation"
                                  >
                                    <b role="presentation" />
                                  </span>
                                </span>
                              </span>
                              <span
                                className="dropdown-wrapper"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="form-group">
                            <label
                              className="input-label d-flex"
                              htmlFor="amount"
                            >
                              Amount
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="amount"
                              id="amount"
                              step=".01"
                              placeholder="Ex:500"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label
                              className="input-label d-flex align-items-center gap-1"
                              htmlFor="reference"
                            >
                              Reference <small>(Optional)</small>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="reference"
                              placeholder="Ex:abc990"
                              id="reference"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end gap-3">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn--primary px-4"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="card  mt-3">
              <div className="card-header text-capitalize">
                <h4 className="mb-0">Filter options</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 pt-3">
                    <form>
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <div className="mb-3">
                            <input
                              type="date"
                              name="from"
                              id="start-date-time"
                              defaultValue
                              className="form-control"
                              title="From date"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="mb-3">
                            <input
                              type="date"
                              name="to"
                              id="end-date-time"
                              defaultValue
                              className="form-control"
                              title="To date"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="mb-3">
                            <select
                              name="transaction_type"
                              className="form-control"
                              title="Select transaction type"
                            >
                              <option value>All</option>
                              <option value="add_fund_by_admin">
                                Add fund by admin
                              </option>
                              <option value="add_fund">Add fund</option>
                              <option value="order_refund">Refund order</option>
                              <option value="loyalty_point">
                                Customer loyalty point
                              </option>
                              <option value="order_place">Order place</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="mb-3">
                            <input
                              type="hidden"
                              id="customer-id"
                              name="customer_id"
                              defaultValue="all"
                            />
                            <select
                              data-placeholder="
                                                                                                            All customer
                                                    "
                              className="get-customer-list-by-ajax-request form-control form-ellipsis set-customer-value select2-hidden-accessible"
                              data-select2-id={3}
                              tabIndex={-1}
                              aria-hidden="true"
                            >
                              <option value="all" data-select2-id={5}>
                                All customer
                              </option>
                              <option value disabled data-select2-id={6}>
                                Select your option
                              </option>
                            </select>
                            <span
                              className="select2 select2-container select2-container--default"
                              dir="ltr"
                              data-select2-id={4}
                              style={{ width: "431.458px" }}
                            >
                              <span className="selection">
                                <span
                                  className="select2-selection select2-selection--single"
                                  role="combobox"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabIndex={0}
                                  aria-disabled="false"
                                  aria-labelledby="select2-tclq-container"
                                >
                                  <span
                                    className="select2-selection__rendered"
                                    id="select2-tclq-container"
                                    role="textbox"
                                    aria-readonly="true"
                                  >
                                    <span className="select2-selection__placeholder">
                                      All customer
                                    </span>
                                  </span>
                                  <span
                                    className="select2-selection__arrow"
                                    role="presentation"
                                  >
                                    <b role="presentation" />
                                  </span>
                                </span>
                              </span>
                              <span
                                className="dropdown-wrapper"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn--primary px-4">
                          <i class="fa fa-bars" aria-hidden="true"></i> Filter
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="card mt-3">
              <div className="card-header text-capitalize">
                <h4 className="mb-0">Summary</h4>
              </div>
              <div className="card-body">
                <div className="d-flex flex-wrap gap-3">
                  <div className="order-stats flex-grow-1">
                    <div className="order-stats__content">
                      <i class="fa fa-credit-card" aria-hidden="true"></i>
                      <h6 className="order-stats__subtitle">Debit</h6>
                    </div>
                    <span className="order-stats__title fz-14 text--primary">
                      $7,335.00
                    </span>
                  </div>
                  <div className="order-stats flex-grow-1">
                    <div className="order-stats__content">
                      <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                      <h6 className="order-stats__subtitle">Credit</h6>
                    </div>
                    <span className="order-stats__title fz-14 text-warning">
                      $64,739.00
                    </span>
                  </div>
                  <div className="order-stats flex-grow-1">
                    <div className="order-stats__content">
                      <i class="fa fa-google-wallet" aria-hidden="true"></i>
                      <h6 className="order-stats__subtitle">Balance</h6>
                    </div>
                    <span className="order-stats__title fz-14 text-success">
                      $57,404.00
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="card mt-3">
              <div className="card-header text-capitalize gap-2">
                <h4 className="mb-0 text-nowrap ">
                  Transactions
                  <span className="badge badge-soft-dark radius-50 fz-12 ml-1">
                    {count}
                  </span>
                </h4>
                <div className="d-flex justify-content-end">
                  <div className="dropdown text-nowrap">
                    <button
                      onClick={handleExport}
                      type="button"
                      className="btn btn-outline--primary"
                      data-toggle="dropdown"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i> Export
                    </button>
                  </div>
                </div>
              </div>
              {loyalty?.length > 0 ? (
                <div
                  className="table-responsive"
                  style={{
                    overflow: "scroll",
                    height: "400px",
                    overflowY: "hidden",
                  }}
                >
                  <table
                    id="datatable"
                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table text-left"
                  >
                    <thead className="thead-light thead-50 text-capitalize">
                      <tr>
                        <th>SL</th>
                        <th>Order ID</th>
                        <th>Customer</th>
                        <th>Points</th>
                        {/* <th>Debit</th>
                     <th>Balance</th> */}
                        <th className="text-center">Transaction type</th>
                        <th>Email-Id</th>
                        <th>Mobile Number</th>
                        {/* <th>Reference</th> */}
                        <th className="text-center">Date & Time </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loyalty?.map((data, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{data?.orderId}</td>
                            <td>
                              <Link
                                to="/Customerdetails"
                                onClick={() => {
                                  secureLocalStorage.setItem(
                                    "customerid",
                                    data?.userId?._id
                                  );
                                }}
                                className="title-color hover-c1"
                              >
                                {data?.userId?.userName}
                              </Link>
                            </td>
                            <td>{data?.point}</td>

                            <td className="text-center">
                              <span className="badge badge-soft-success">
                                Add Point
                              </span>
                            </td>
                            <td>
                              {data?.userId?.email === ""
                                ? "Na"
                                : data?.userId?.email}
                            </td>
                            <td>
                              {data?.userId?.mobile_number === ""
                                ? "Na"
                                : data?.userId?.mobile_number}
                            </td>
                            <td className="text-center">
                              {data?.createdAt?.slice(0, 10)}{" "}
                              {data?.createdAt?.slice(11, 19)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div class="table-responsive datatable-custom">
                  <div class="text-center p-4">
                    <img
                      class="mb-3 w-160"
                      src="https://6valley.6amtech.com/public/assets/back-end/img/empty-state-icon/default.png"
                      alt="Image Description"
                    />
                    <p class="mb-0 order-stats__subtitle">No Data found</p>
                  </div>
                  <div class="d-flex justify-content-center mt-4"></div>
                </div>
              )}
              {/* <div className="table-responsive">
                <table
                  id="datatable"
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table text-left"
                >
                  <thead className="thead-light thead-50 text-capitalize">
                    <tr>
                      <th>SL</th>
                      <th>Transaction ID</th>
                      <th>Customer</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Balance</th>
                      <th>Transaction type</th>
                      <th>Reference</th>
                      <th className="text-center">Created at</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>05f4fb07-e72a-48e4-825c-2aa2b14e1b5a</td>
                      <td>
                        <Link
                          to="/Customerdetails"
                          className="title-color hover-c1"
                        >
                          Devid Jack
                        </Link>
                      </td>
                      <td>
                        $500.00
                        <span className="text-sm badge badge-soft-success">
                          + $150.00 Admin bonus
                        </span>
                      </td>
                      <td>$0.00</td>
                      <td>$1,725.00</td>
                      <td>
                        <span className="badge badge-soft-success">
                          Add fund
                        </span>
                      </td>
                      <td>Add funds to wallet</td>
                      <td className="text-center">2023/10/12 </td>
                    </tr>
                  
                  </tbody>
                </table>
              </div> */}
              <div className="table-responsive mt-4">
                <div className="px-4 d-flex justify-content-lg-end"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loyaltyreport;
