import logo from "./logo.svg";
import "./App.css";
import Allproducts from "./Components/page/Allproducts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Home from "./Components/Home";
import Panding from "./Components/page/Panding";
import Confrimproducts from "./Components/page/Confrimproducts";
import Products_list from "./Components/page/Products_list";
import Addnewproducts from "./Components/page/Addnewproducts";
import Orderdetails from "./Components/page/Orderdetails";
import { useEffect, useState } from "react";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import Bannerlist from "./Components/page/Bannerlist";
import Editbanner from "./Components/page/Editbanner";
import Addbanner from "./Components/page/Addbanner";
import Notifications from "./Components/page/Notifications ";
import NotificationsEdit from "./Components/page/NotificationsEdit";
import Usermanagement from "./Components/page/Usermanagement";
import UserOrderdetails from "./Components/page/UserOrderdetails";
import Paymentmanagement from "./Components/page/Paymentmanagement";
import Vendor_manage from "./Components/page/Vendor_manage";
import Subscription from "./Components/page/Subscription";
import Profile from "./Components/page/Profile";
import Login from "./Components/Login";
import Packaging from "./Components/page/Packaging";
import Screen from "./Components/Screen";
import Overviewsale from "./Components/page/Overviewsale";
import Deliverdproducs from "./Components/page/Deliverdproducs";
import Outfordelivery from "./Components/page/Outfordelivery";
import Canceledproducts from "./Components/page/Canceledproducts";
import Returnedproducts from "./Components/page/Returnedproducts";
import Failedtodelivery from "./Components/page/Failedtodelivery";
import Sallerdetails from "./Components/page/Sallerdetails";
import Sallerorder from "./Components/page/Sallerorder";
import Sallerproducts from "./Components/page/Sallerproducts";
import Sallersatting from "./Components/page/Sallersatting";
import Sallertransacations from "./Components/page/Sallertransacations";
import Sallerreview from "./Components/page/Sallerreview";
import Sallerorderdetails from "./Components/page/Sallerorderdetails";
import Productsmange from "./Components/page/Productsmange";
import Editproducts from "./Components/page/Editproducts";
import Addsubscription from "./Components/page/Addsubscription";
import Massage from "./Components/page/Massage";
import Cetagory from "./Components/page/Cetagory";
import EditCetagory from "./Components/page/EditCetagory";
import Subcetagory from "./Components/page/Subcetagory";
import Editsubcetagory from "./Components/page/Editsubcetagory";
import Subsubcategory from "./Components/page/Subsubcategory";
import Edit_subsubcetagory from "./Components/page/Edit_subsubcetagory";
import Userdetails from "./Components/page/Userdetails";
import User_reaview from "./Components/page/User_reaview";
import Sallereditproducts from "./Components/page/Sallereditproducts";
import Newhello from "./Components/Newhello";
import Products_details from "./Components/page/Products_details";
import Products_edit from "./Components/page/Products_edit";
import Subscriberlist from "./Components/page/Subscriberlist";
import Refundpanding from "./Components/page/Refundpanding";
import Refundapproved from "./Components/page/Refundapproved";
import Refundrefunded from "./Components/page/Refundrefunded";
import Refundreject from "./Components/page/Refundreject";
import Refundstatus from "./Components/page/Refundstatus";
import { Category } from "@syncfusion/ej2-react-charts";
import Delivered from "./Components/page/Delivered";
import Canceled from "./Components/page/Canceled";
import Returned from "./Components/page/Returned";
import Coupan from "./Components/page/Coupan";
import Coupanupdate from "./Components/page/Coupanupdate";
import Chatwithadmin from "./Components/page/Chatwithadmin";
import Withdrow from "./Components/page/Withdrow";
import Customerlist from "./Components/page/Customerlist";
import Customerdetails from "./Components/page/Customerdetails";
import Customerreview from "./Components/page/Customerreview";
import Wallet from "./Components/page/Wallet";
import Walletbonussetup from "./Components/page/Walletbonussetup";
import Walletbonusedit from "./Components/page/Walletbonusedit";
import Loyaltyreport from "./Components/page/Loyaltyreport";
import Addnewseller from "./Components/page/Addnewseller";
import Sellerlist from "./Components/page/Sellerlist";
import Sellerdetails from "./Components/page/Sellerdetails";
import Withdrawview from "./Components/page/Withdrawview";
import Withdrawmethod from "./Components/page/Withdrawmethod";
import Withdrawmethodadd from "./Components/page/Withdrawmethodadd";
import Addnewdeliveryman from "./Components/page/Addnewdeliveryman";
import Deliverymanlist from "./Components/page/Deliverymanlist";
import Earningstatement from "./Components/page/Earningstatement";
import Cashcollected from "./Components/page/Cashcollected";
import Updatedeliveryman from "./Components/page/Updatedeliveryman";
import Rating from "./Components/page/Rating";
import Emergencycontact from "./Components/page/Emergencycontact";
import Customermessage from "./Components/page/Customermessage";
import Customermessageview from "./Components/page/Customermessageview";
import Addnewbrands from "./Components/page/Addnewbrands";
import Brandlist from "./Components/page/Brandlist";
import Updatebrand from "./Components/page/Updatebrand";
import Productattribute from "./Components/page/Productattribute";
import Updateattribute from "./Components/page/Updateattribute";
import Newproductsrequest from "./Components/page/Newproductsrequest";
import Productupdaterequest from "./Components/page/Productupdaterequest";
import Vendorapprovedproductlist from "./Components/page/Vendorapprovedproductlist";
import Vendordeniedproductlist from "./Components/page/Vendordeniedproductlist";
import Protect from "./Components/page/Protect";
import Deliverycharge from "./Components/page/Deliverycharge";
import Aboutus from "./Components/page/Aboutus";
import Termcondition from "./Components/page/Termcondition";
import Privacypolicy from "./Components/page/Privacypolicy";
import Faq from "./Components/page/Faq";
import Contactus from "./Components/page/Contactus";
import Vendorchat from "./Components/page/Vendorchat";
import Test from "./Components/page/Test";
import Maincetagory from "./Components/page/Maincetagory";
import EditMaincategory from "./Components/page/EditMaincategory";
import Error from "./Components/Error";
import Addnewsize from "./Components/page/Addnewsize";
import Addnewcolor from "./Components/page/Addnewcolor";

import Addnewproducttype from "./Components/page/Addnewproducttype";
import Dealsoftheday from "./Components/page/Dealsoftheday";
import Returnpolicy from "./Components/page/Returnpolicy";
import Cancellaionpolicy from "./Components/page/Cancellaionpolicy";
import Refundpolicy from "./Components/page/Refundpolicy";
import Addadvertisment from "./Components/page/Addadvertisment";
import Alltransication from "./Components/page/Alltransication";
import Withdrawviewdetails from "./Components/page/Withdrawviewdetails";
import Suggestion from "./Components/page/Suggestion";
import Deliverywithdraw from "./Components/page/Deliverywithdraw";
import Deliverywithdrawreview from "./Components/page/Deliverywithdrawreview";
import Dealsoftoday from "./Components/page/Dealsoftoday";
import Querylist from "./Components/page/Querylist";
function App() {
  const [randomResult, setRandomResult] = useState("hello");

  useEffect(() => {
    // Generate 8-digit random number
    const randomNumber = Math.floor(Math.random() * 90000000) + 10000000;

    // Generate 2 random alphabets
    const randomAlphabets =
      String.fromCharCode(65 + Math.floor(Math.random() * 26)) +
      String.fromCharCode(65 + Math.floor(Math.random() * 26));

    // Combine the random number and alphabets
    const result = randomNumber.toString() + randomAlphabets;

    // Set the result in the state
    // setRandomResult(result);
  }, []);
  const location = useLocation();
  return (
    <div>
      {location.pathname !== "/" && <Header />}
      {location.pathname !== "/" && <Sidebar />}
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/home" element={<Protect ComponentName={Home} />}></Route>

        <Route
          path="/profile"
          element={<Protect ComponentName={Profile} />}
        ></Route>
        <Route
          path="/allorder"
          element={<Protect ComponentName={Allproducts} />}
        ></Route>

        <Route
          path="/Pending"
          element={<Protect ComponentName={Panding} />}
        ></Route>

        <Route
          path="/Confirmed"
          element={<Protect ComponentName={Confrimproducts} />}
        ></Route>

        <Route
          path="/Rejected"
          element={<Protect ComponentName={Packaging} />}
        ></Route>

        <Route
          path="/Delivered"
          element={<Protect ComponentName={Delivered} />}
        ></Route>

        <Route
          path="/Cancelled"
          element={<Protect ComponentName={Canceled} />}
        ></Route>

        <Route
          path="/Returned"
          element={<Protect ComponentName={Returned} />}
        ></Route>

        <Route
          path="/Add Attribute"
          element={<Protect ComponentName={Coupan} />}
        ></Route>

        <Route
          path="/coupanupdate"
          element={<Protect ComponentName={Coupanupdate} />}
        ></Route>

        <Route
          path="/message"
          element={<Protect ComponentName={Massage} />}
        ></Route>
        <Route
          path="/chatwithadmin"
          element={<Protect ComponentName={Chatwithadmin} />}
        ></Route>

        <Route
          path="/Vendor Withdrow"
          element={<Protect ComponentName={Withdrow} />}
        ></Route>
        <Route
          path="/Customer List"
          element={<Protect ComponentName={Customerlist} />}
        ></Route>

        <Route
          path="/Customerdetails"
          element={<Protect ComponentName={Customerdetails} />}
        ></Route>

        <Route
          path="/Customer Reviews"
          element={<Protect ComponentName={Customerreview} />}
        ></Route>
        <Route
          path="/Add New Staff"
          element={<Protect ComponentName={Wallet} />}
        ></Route>
        <Route
          path="/Staff List"
          element={<Protect ComponentName={Walletbonussetup} />}
        ></Route>
        <Route
          path="/walletbonusedit"
          element={<Protect ComponentName={Walletbonusedit} />}
        ></Route>
        <Route
          path="/Loyalty Points"
          element={<Protect ComponentName={Loyaltyreport} />}
        ></Route>
        <Route
          path="/Add New Vendor"
          element={<Protect ComponentName={Addnewseller} />}
        ></Route>
        <Route
          path="/Vendor List"
          element={<Protect ComponentName={Sellerlist} />}
        ></Route>
        <Route
          path="/sellerdetails"
          element={<Protect ComponentName={Sallerdetails} />}
        ></Route>
        <Route
          path="/withdrawview"
          element={<Protect ComponentName={Withdrawview} />}
        ></Route>
        <Route
          path="/withdrawmethod"
          element={<Protect ComponentName={Withdrawmethod} />}
        ></Route>
        <Route
          path="/withdrawmethodadd"
          element={<Protect ComponentName={Withdrawmethodadd} />}
        ></Route>
        <Route
          path="/Add New Deliveryman"
          element={<Protect ComponentName={Addnewdeliveryman} />}
        ></Route>
        <Route
          path="/Delivery Man List"
          element={<Protect ComponentName={Deliverymanlist} />}
        ></Route>
        <Route
          path="/earningstatement"
          element={<Protect ComponentName={Earningstatement} />}
        ></Route>
        <Route
          path="/cashcollected"
          element={<Protect ComponentName={Cashcollected} />}
        ></Route>
        <Route
          path="/updatedeliveryman"
          element={<Protect ComponentName={Updatedeliveryman} />}
        ></Route>
        <Route
          path="/rating"
          element={<Protect ComponentName={Rating} />}
        ></Route>
        <Route
          path="/Emergency Contact"
          element={<Protect ComponentName={Emergencycontact} />}
        ></Route>
        <Route
          path="/customermessage"
          element={<Protect ComponentName={Customermessage} />}
        ></Route>
        <Route
          path="/customermessageview"
          element={<Protect ComponentName={Customermessageview} />}
        ></Route>
        <Route 
          path="/Brands"
          element={<Protect ComponentName={Addnewbrands} />}
        ></Route>
        <Route
          path="/brandlist"
          element={<Protect ComponentName={Brandlist} />}
        ></Route>
        <Route
          path="/Shop Report"
          element={<Protect ComponentName={Updatebrand} />}
        ></Route>
       
       
        <Route
          path="/productattribute"
          element={<Protect ComponentName={Productattribute} />}
        ></Route>

        <Route
          path="/updateattribute"
          element={<Protect ComponentName={Updateattribute} />}
        ></Route>

        <Route
          path="/Today Offer InntOut"
          element={<Protect ComponentName={Dealsoftoday} />}
        ></Route>

        <Route
          path="/New Products Requests"
          element={<Protect ComponentName={Newproductsrequest} />}
        ></Route>

        <Route
          path="/productupdaterequest"
          element={<Protect ComponentName={Productupdaterequest} />}
        ></Route>

        <Route
          path="/Approved Products"
          element={<Protect ComponentName={Vendorapprovedproductlist} />}
        ></Route>


<Route
          path="/Contact Messages"
          element={<Protect ComponentName={Querylist} />}
        ></Route>

        <Route
          path="/Denied Products"
          element={<Protect ComponentName={Vendordeniedproductlist} />}
        ></Route>

        <Route
          path="/Shipped"
          element={<Protect ComponentName={Outfordelivery} />}
        ></Route>

        <Route
          path="/canceledproducts"
          element={<Protect ComponentName={Canceledproducts} />}
        ></Route>

        <Route
          path="/returnedproducts"
          element={<Protect ComponentName={Returnedproducts} />}
        ></Route>

        <Route
          path="/Not delivered"
          element={<Protect ComponentName={Failedtodelivery} />}
        ></Route>

        <Route
          path="/sallerdetails"
          element={<Protect ComponentName={Sallerdetails} />}
        ></Route>

        <Route
          path="/sallerorder"
          element={<Protect ComponentName={Sallerorder} />}
        ></Route>

        <Route
          path="/sallerproducts"
          element={<Protect ComponentName={Sallerproducts} />}
        ></Route>

        <Route
          path="/Deal of the day Innt"
          element={<Protect ComponentName={Dealsoftheday} />}
        ></Route>
        <Route
          path="/sallersatting"
          element={<Protect ComponentName={Sallersatting} />}
        ></Route>

        <Route
          path="/sallertransacation"
          element={<Protect ComponentName={Sallertransacations} />}
        ></Route>

        <Route
          path="/sallerreview"
          element={<Protect ComponentName={Sallerreview} />}
        ></Route>

        <Route
          path="/sallerorderdetils"
          element={<Protect ComponentName={Sallerorderdetails} />}
        ></Route>

        <Route
          path="/salleproductsedit"
          element={<Protect ComponentName={Sallereditproducts} />}
        ></Route>

        <Route
          path="/devivardproducts"
          element={<Protect ComponentName={Deliverdproducs} />}
        ></Route>

        <Route
          path="/products_list"
          element={<Protect ComponentName={Products_list} />}
        ></Route>

        <Route
          path="/addnewproducts"
          element={<Protect ComponentName={Addnewproducts} />}
        ></Route>

        <Route
          path="/orderdetails"
          element={<Protect ComponentName={Orderdetails} />}
        ></Route>

        <Route
          path="/add_banner"
          element={<Protect ComponentName={Addbanner} />}
        ></Route>

        <Route
          path="/Banners"
          element={<Protect ComponentName={Bannerlist} />}
        ></Route>

        <Route
          path="/editbannerlist"
          element={<Protect ComponentName={Editbanner} />}
        ></Route>

        <Route
          path="/Send Notification"
          element={<Protect ComponentName={Notifications} />}
        ></Route>

        <Route
          path="/editnotification"
          element={<Protect ComponentName={NotificationsEdit} />}
        ></Route>

        <Route
          path="/userlist"
          element={<Protect ComponentName={Usermanagement} />}
        ></Route>

        <Route
          path="/userreaview"
          element={<Protect ComponentName={User_reaview} />}
        ></Route>

        <Route
          path="/userorderlist"
          element={<Protect ComponentName={UserOrderdetails} />}
        ></Route>

        <Route
          path="/paymentmanage"
          element={<Protect ComponentName={Paymentmanagement} />}
        ></Route>

        <Route
          path="/vendormanage"
          element={<Protect ComponentName={Vendor_manage} />}
        ></Route>

        <Route
          path="/subscription"
          element={<Protect ComponentName={Subscription} />}
        ></Route>

        <Route
          path="/overviewsale"
          element={<Protect ComponentName={Overviewsale} />}
        ></Route>

        <Route
          path="/allproductsmanage"
          element={<Protect ComponentName={Productsmange} />}
        ></Route>

        <Route
          path="/productsdetails"
          element={<Protect ComponentName={Products_details} />}
        ></Route>

        <Route
          path="/productseditproducts"
          element={<Protect ComponentName={Products_edit} />}
        ></Route>

        <Route
          path="/subscriberlist"
          element={<Protect ComponentName={Subscriberlist} />}
        ></Route>
        <Route
          path="/addnewsize"
          element={<Protect ComponentName={Addnewsize} />}
        ></Route>
        <Route
          path="/addnewcolor"
          element={<Protect ComponentName={Addnewcolor} />}
        ></Route>

        <Route
          path="/editproducts"
          element={<Protect ComponentName={Editproducts} />}
        ></Route>

        <Route
          path="/addsubscription"
          element={<Protect ComponentName={Addsubscription} />}
        ></Route>

        <Route
          path="/massages"
          element={<Protect ComponentName={Massage} />}
        ></Route>

        <Route
          path="/Cetagory"
          element={<Protect ComponentName={Cetagory} />}
        ></Route>

        <Route
          path="/editcetagory"
          element={<Protect ComponentName={EditCetagory} />}
        ></Route>

        <Route
          path="/Subcategory"
          element={<Protect ComponentName={Subcetagory} />}
        ></Route>

        <Route
          path="/editsubcetagory"
          element={<Protect ComponentName={Editsubcetagory} />}
        ></Route>

        <Route
          path="/Sub Subcetagory"
          element={<Protect ComponentName={Subsubcategory} />}
        ></Route>

        <Route
          path="/editsubsubcetagory"
          element={<Protect ComponentName={Edit_subsubcetagory} />}
        ></Route>

        <Route
          path="/userdetails"
          element={<Protect ComponentName={Userdetails} />}
        ></Route>

        <Route
          path="/Pending Refund"
          element={<Protect ComponentName={Refundpanding} />}
        ></Route>

        <Route
          path="/Approved Refund"
          element={<Protect ComponentName={Refundapproved} />}
        ></Route>

        <Route
          path="/Refunded Refund"
          element={<Protect ComponentName={Refundrefunded} />}
        ></Route>

        <Route
          path="/Rejected Refund"
          element={<Protect ComponentName={Refundreject} />}
        ></Route>

        <Route
          path="/refundstatus"
          element={<Protect ComponentName={Refundstatus} />}
        ></Route>

        <Route
          path="/hello"
          element={<Protect ComponentName={Screen} />}
        ></Route>

        <Route
          path="/newhello"
          element={<Protect ComponentName={Newhello} />}
        ></Route>

        <Route
          path="/Tax & Fare charge"
          element={<Protect ComponentName={Deliverycharge} />}
        ></Route>
        <Route
          path="/About Us"
          element={<Protect ComponentName={Aboutus} />}
        ></Route>

        <Route
          path="/Term & Conditions"
          element={<Protect ComponentName={Termcondition} />}
        ></Route>
       

       
        <Route
          path="/Privacy & Policy"
          element={<Protect ComponentName={Privacypolicy} />}
        ></Route>

        <Route
          path="/Return Policy"
          element={<Protect ComponentName={Returnpolicy} />}
        ></Route>

        <Route
          path="/Delivery Man Withdrow"
          element={<Protect ComponentName={Deliverywithdraw} />}
        ></Route>

        <Route
          path="/deliverywithdrawreview"
          element={<Protect ComponentName={Deliverywithdrawreview} />}
        ></Route>
        <Route
          path="/Improvement Suggestion"
          element={<Protect ComponentName={Suggestion} />}
        ></Route>

        <Route
          path="/All Transaction"
          element={<Protect ComponentName={Alltransication} />}
        ></Route>

        <Route
          path="/withdrawviewdetails"
          element={<Protect ComponentName={Withdrawviewdetails} />}
        ></Route>

        <Route
          path="/Add Advertisement"
          element={<Protect ComponentName={Addadvertisment} />}
        ></Route>

        <Route
          path="/Cancellation Policy"
          element={<Protect ComponentName={Cancellaionpolicy} />}
        ></Route>

        <Route
          path="/Refund Policy"
          element={<Protect ComponentName={Refundpolicy} />}
        ></Route>

        <Route path="/Faq" element={<Protect ComponentName={Faq} />}></Route>

        <Route
          path="/Contact Us"
          element={<Protect ComponentName={Contactus} />}
        ></Route>

        <Route
          path="/vendorchat"
          element={<Protect ComponentName={Vendorchat} />}
        ></Route>
        <Route
          path="/Main Cetagory"
          element={<Protect ComponentName={Maincetagory} />}
        ></Route>

        <Route
          path="/editMaincategory"
          element={<Protect ComponentName={EditMaincategory} />}
        ></Route>

        <Route
          path="/Product Type"
          element={<Protect ComponentName={Addnewproducttype} />}
        ></Route>
        <Route path="/test" element={<Protect ComponentName={Test} />}></Route>

        <Route path="*" element={<Error />}></Route>
      </Routes>
      {location.pathname !== "/"}
    </div>
  );
}

export default App;
