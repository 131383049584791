import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { Link } from 'react-router-dom'
import Sidebarr from '../Sidebar'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'
import secureLocalStorage from 'react-secure-storage'

const Sallersatting = () => {


    const [commission, setcommission] = useState();
    let sellerid = secureLocalStorage.getItem("sellerid");
    useEffect(() => {
        sellerdetails();
      }, [0]);
    
      const sellerdetails = () => {
        const sellerdata = {
          sallerId: sellerid,
        };
     axios
          .post(
            `${process.env.REACT_APP_API_KEY}admin/api/sallerDetails`,
            sellerdata
          )
          .then((res) => {
    
            setcommission(res?.data?.data?.comission);
          })
          .catch((error) => {});
      };




      const commisstiondata = (e) =>{
e.preventDefault();
const data = {
    sellerId:sellerid,
    comission : commission
}


 axios
          .post(
            `${process.env.REACT_APP_API_KEY}admin/api/updateSeller_commision`,
            data
          )
          .then((res) => {
            toast.success(res.data.message)
            sellerdetails();

          })
          .catch((error) => {

          });
      };
    return (
        <div>
    {/* <Header /> */}
    <Toaster/>
    <div
      className="container row" style={{ paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px" }}
      
    >
      <div className="col-lg-3 col-md-4" >
        {/* <Sidebarr /> */}
      </div>

      <div
        className="col-lg-9 col-md-8"
        style={{  marginTop: "60px" }}
      >
         <div className="mt-3 mb-5">
                            <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                                <img width={20} src="img/officer.png" alt />
                                Vendor details
                            </h2>
                        </div>
                        <div className="flex-between d-sm-flex row align-items-center justify-content-between mb-2 mx-1">
                            <div>
                            </div>
                        </div>
                        <div className="page-header">
                            
                            <div className="js-nav-scroller hs-nav-scroller-horizontal">
                                <ul className="nav nav-tabs flex-wrap page-header-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link " to="/sallerdetails">Shop Overview</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " to="/sallerorder">Order</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " to="/sallerproducts">Product</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" to="/sallersatting">Setting</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link " to="/sallertransacation">Transaction</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link" to="/sallerreview">Review</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <form  style={{ textAlign: 'left' }} onSubmit={commisstiondata}>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-0"> Sales Commission : </h5>
                                            
                                        </div>
                                        <div className="card-body">
                                           
                                            <div className="form-group">
                                                <label>Commission ( % )</label>
                                                <input onChange={((e)=>{setcommission(e.target.value)})} value={commission} type="number" defaultValue className="form-control" name="commission" />
                                            </div>
                                            <button type="submit" className="btn btn--primary">Update</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>





       
    )
}

export default Sallersatting
